import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueBasicAlert from 'vue-basic-alert'
import VueHtmlToPaper from 'vue-html-to-paper';

axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')

createApp(App).use(store).use(router).use(VueBasicAlert).use(VueHtmlToPaper).mount('#app')
