<template>
    <button class="btn btn-primary mb-3" @click="alertParent('create')">Ajouter un employé +</button>
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Liste de vos employés</h5>

            <div class="active">
                <table class="table datatable">
                    <thead>
                        <tr>
                            <th scope="col">Nom</th>
                            <th scope="col">Prénoms</th>
                            <th scope="col">Contact</th>
                            <th scope="col">Poste</th>
                            <th scope="col">Salaire(/jour)</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="employee in employees" :key="employee.id">
                            <td>{{ employee.first_name }}</td>
                            <td>{{ employee.last_name }}</td>
                            <td>{{ employee.phone }}</td>
                            <td><span class="badge bg-info">{{
                                employee.post.title }}</span>
                            </td>
                            <td>{{ cashFormat(employee.salary) }}</td>
                            <td>
                                <div>
                                    <a class="btn btn-primary btn-sm" title="Modifier" data-bs-toggle="modal"
                                        :data-bs-target="'#modemp' + employee.id"><i class="bi bi-pencil"></i></a>
                                    -
                                    <a class="btn btn-danger btn-sm" data-bs-toggle="modal"
                                        :data-bs-target="'#supemp' + employee.id" title="Supprimer"><i
                                            class="bi bi-trash"></i></a>
                                </div>

                                <!-- Delete modal -->
                                <div class="modal fade" :id="'supemp' + employee.id" tabindex="-1">
                                    <div class="modal-dialog modal-dialog-centered">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title text-center">
                                                    Confirmer en entrant votre code de sécurité
                                                </h5>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                    aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body">
                                                <!-- No Labels Form -->
                                                <form class="row g-3">
                                                    <div class="col-md-6">
                                                        <input type="password" class="form-control" placeholder="******"
                                                            v-model="this.inputcode" />
                                                    </div>
                                                </form>
                                                <!-- End No Labels Form -->
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
                                                    Annuler
                                                </button>
                                                <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                                                    @click="deleteEmployee(employee.id)">
                                                    Je confirme
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Update modal -->
                                <div class="modal fade" :id="'modemp' + employee.id" tabindex="-1">
                                    <div class="modal-dialog modal-dialog-centered">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title text-center">
                                                    Modification
                                                </h5>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                    aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body">
                                                <!-- No Labels Form -->
                                                <form class="row g-3">
                                                    <div class="col-md-6">
                                                        <input type="email" class="form-control"
                                                            :placeholder="employee.first_name"
                                                            v-model="updatemployee.first_name" />
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" class="form-control"
                                                            :placeholder="employee.last_name"
                                                            v-model="updatemployee.last_name" />
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" class="form-control"
                                                            :placeholder="employee.phone" v-model="updatemployee.phone" />
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" class="form-control"
                                                            :placeholder="cashFormat(employee.salary)"
                                                            v-model="updatemployee.salary" />
                                                    </div>
                                                    <div class="">
                                                        <select id="inputstate" class="form-select"
                                                            aria-label="Default select example"
                                                            v-model="updatemployee.post_id">
                                                            <option value="">-- Poste --</option>
                                                            <option v-for="post in posts" :value="post.id" :key="post.id">{{
                                                                post.title }}</option>
                                                        </select>
                                                    </div>
                                                </form>
                                                <!-- End No Labels Form -->
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
                                                    Annuler
                                                </button>
                                                <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                                                    @click="updateEmployee(employee.id)">
                                                    Confirmer
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <!-- Postes -->

    <vue-basic-alert :duration="300" :closeIn="1500" ref="alert" />
</template>

<script>

import axios from 'axios'

export default {
    name: 'EmployeeComponent',

    data() {
        return {
            employees: [],
            posts: [],
            updatemployee: { first_name: '', last_name: '', phone: '', post_id: '', salary: '' },
            inputcode: ''
        }
    },

    mounted() {
        this.getEmployees()
        this.getPosts()
    },

    methods: {

        alertParent(mess) {
            this.$emit('employeeviewchange', mess)
        },

        cashFormat(cash) {
            return (parseInt(cash)).toLocaleString('fr-FR', {
                style: 'currency',
                currency: 'CFA',
            })
        },

        getEmployees() {
            axios.get(this.$store.state.URL_API + 'getEmployees')
                .then((res) => {
                   // console.log(res)
                    if (res.data.status) {
                        this.employees = res.data.data
                    }
                })
                .catch((e) => {
                   console.error(e)
                })
        },

        getPosts() {
            axios.get(this.$store.state.URL_API + 'getPosts')
                .then((res) => {
                   // console.log(res)
                    if (res.data.status) {
                        this.posts = res.data.data
                    }
                })
                .catch((e) => {
                   console.error(e)
                })
        },

        createEmployee() {
            axios.post(this.$store.state.URL_API + 'createEmployee', this.employee)
                .then((res) => {
                   // console.log(res)
                    if (res.data.status) {
                        this.getEmployees()
                        this.employee = { first_name: '', last_name: '', phone: '', post_id: '', salary: '' };
                    }
                })
                .catch((e) => {
                   console.error(e)
                })

        },

        updateEmployee(id) {
            axios.post(this.$store.state.URL_API + 'updateEmployee/' + id, this.updatemployee)
                .then((res) => {
                   // console.log(res)
                    if (res.data.status) {
                        this.updatemployee = { first_name: '', last_name: '', phone: '', post_id: '', salary: '' }
                        this.getEmployees()
                    }
                })
                .catch((e) => {
                   console.error(e)
                })
        },

        deleteEmployee(id) {
            axios.post(this.$store.state.URL_API + 'deleteEmployee/' + id, { code: this.inputcode })
                .then((res) => {
                    this.total_amount = 0
                    if (res.data.status) {
                       // console.log(res)
                        this.getEmployees()
                        this.inputcode = ""
                    } else {
                        this.$refs.alert
                            .showAlert(
                                'error',
                                res.data.message,
                                "Désolé",
                            )
                    }
                })
                .catch((e) => {
                   console.error(e)
                    this.$refs.alert
                        .showAlert(
                            'error',
                            e.response.message,
                            "Désolé",
                        )
                })
        }
    }
}

</script>