<template>
    <div class="row">
        <div class="col-lg-6">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Prix unitaire du sac de manioc</h5>
                    <!-- No Labels Form -->
                    <form class="row g-3" @submit.prevent="this.updateConfig({ bag_price: newPrice })">
                        <div class="col-md-6">
                            <input v-model="newPrice" type="text" class="form-control"
                                :placeholder="cashFormat(currentPrice)" required>
                        </div>
                        <div class="col-md-6">
                            <input type="submit" class="btn btn-primary" value="Modifier" />
                        </div>
                    </form><!-- End No Labels Form -->

                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title text-danger">*Mot de passe de sécutité*</h5>
                    <!-- No Labels Form -->
                    <form class="row g-3" @submit.prevent="this.updateConfig(securitycode)">
                        <div class="col-md-3">
                            <input v-model="securitycode.old_password" type="password" class="form-control"
                                placeholder="Ancien mdp..."  minlength="5" required>
                        </div>
                        <div class="col-md-3">
                            <input v-model="securitycode.new_password" type="password" class="form-control"
                                placeholder="Nouveau mdp..." minlength="5" required>
                        </div>
                        <div class="col-md-3">
                            <input type="submit" class="btn btn-primary" value="Modifier" />
                        </div>
                    </form><!-- End No Labels Form -->
                </div>
            </div>
        </div>
    </div>

    <vue-basic-alert :duration="300" :closeIn="2500" ref="alert" />
</template>
    
<script>

import axios from 'axios'

export default {
    name: 'FirstParamComponent',
    components: {
    },

    data() {
        return {
            currentPrice: '',
            newPrice: '',
            securitycode: { old_password: '', new_password: '' }
        }
    },

    created() {

    },

    mounted() {
        this.getData()
    },

    methods: {
        alertParent(mess) {
            this.$emit('collabviewchange', mess)
        },

        cashFormat(cash) {
            return (parseInt(cash)).toLocaleString('fr-FR', {
                style: 'currency',
                currency: 'CFA',
            })
        },

        getData() {
            axios.get(this.$store.state.URL_API + "getConfigs")
                .then((res) => {
                    if (res.data.status) {
                        // console.log("CONFIGS: ", res.data)
                        this.currentPrice = res.data.data
                    }
                })
                .catch((e) => {
                   console.error(e)
                })
        },

        updateConfig(data) {
            axios.post(this.$store.state.URL_API + "updateConfigs", data)
                .then((res) => {
                    if (res.data.status) {
                       // console.log(res)
                        this.newPrice = ""
                        this.securitycode = { old_password: '', new_password: '' }
                        this.getData()
                        this.$refs.alert
                            .showAlert(
                                'success',
                                '',
                                "Modifié avec succès",
                            )
                    } else {
                        this.$refs.alert
                            .showAlert(
                                'error',
                                res.data.message,
                                "Désolé",
                            )
                    }
                })
                .catch((e) => {
                   console.error(e)
                })
        }

    }
}

</script>