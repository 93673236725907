<template>
    <TopBar />

    <main id="main" class="main">

        <div class="pagetitle">

            <h1 class="text-center my-11 bg-secondary text-light card-title">Bienvenue sur votre application de gestion de
                stock et vente </h1>

        </div><!-- End Page Title -->

        <section class="section dashboard">

            <div class="row">

                <!-- Left side columns -->
                <div class="col-lg-9">
                    <div class="row">

                        <!-- Stock Card -->
                        <div class="col-xxl-4 col-md-6">
                            <div class="card info-card bg-primary">

                                <div class="card-body ">
                                    <h5 class="card-title text-white">{{ datenow }}</h5>
                                    <h6 class="text-warning" id="hour"></h6>
                                </div>

                            </div>
                        </div>

                        <!-- Caisse Card -->
                        <div class="col-xxl-4 col-md-6">
                            <div class="card info-card bg-warning">

                                <div class="card-body ">
                                    <h5 class="card-title text-white">Sac en stock</h5>
                                    <h6 class="text-primary">{{ this.stock }}</h6>
                                </div>

                            </div>
                        </div>

                        <!-- Personnels Card -->
                        <div class="col-xxl-4 col-md-6">
                            <div class="card info-card bg-warning">

                                <div class="card-body ">
                                    <h5 class="card-title text-white">Personnes présentes</h5>
                                    <h6 class="text-primary">{{ this.presents }}</h6>
                                </div>

                            </div>
                        </div>

                        <!-- Courbes -->
                        <div class="col-12">
                            <div class="card bg-light">

                                <div class="filter">
                                    <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></a>
                                    <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                        <li class="dropdown-header text-start">
                                            <h6>Modèle de graphe</h6>
                                        </li>

                                        <li class="dropdown-item" @click="initchart('line')">Courbe</li>
                                        <li class="dropdown-item" @click="initchart('bar')">Histogramme</li>
                                    </ul>
                                </div>

                                <div class="card-body">
                                    <h5 class="card-title">Statistiques des ventes de la semaine</h5>

                                    <!-- Line Chart -->
                                    <div>
                                        <canvas id="stats"></canvas>
                                    </div>
                                    <!-- End Line Chart -->

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <!-- Activités Recentes -->
                <div class="col-lg-3">

                    <div class="card">

                        <div class="card-body">
                            <h5 class="card-title">Activités récentes</h5>

                            <div class="activity">

                                <div v-if="activities.length != 0">
                                    <div class="activity-item d-flex" v-for="activity in activities" :key="activity.id">
                                        <div class="activite-label">{{ activity.created_at.slice(0, 10) }}</div>
                                        <i class='bi bi-circle-fill activity-badge text-success align-self-start'></i>
                                        <div class="activity-content">
                                            <span class="text-bold"><span class="text-primary"></span>{{
                                                activity.created_at.slice(11, 16) }}</span> <br>
                                            {{ activity.content }}
                                        </div>
                                    </div>
                                </div>

                                <div class="activity-item d-flex" v-else>
                                    <div class="activity-content">
                                        Pas d'activités récentes
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div><!-- End Recent Activity -->

                </div>

            </div>
        </section>

    </main><!-- End #main -->

    <FootBar />

    <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
            class="bi bi-arrow-up-short"></i></a>
</template>

<script>

import TopBar from '@/components/layouts/top.vue'
import FootBar from '@/components/layouts/foot.vue'

import axios from 'axios'
import Chart from 'chart.js/auto'

export default {
    name: 'DashboardView',
    components: {
        TopBar,
        FootBar,
    },

    data() {
        return {
            stock: 0,
            presents: 0,
            trades: [],
            activities: [],
            myChart: null,
        }
    },

    computed: {
        datenow() {
            var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            var today = new Date();
            return today.toLocaleDateString("fr-FR", options);
        }
    },

    methods: {

        cashFormat(cash) {
            return (parseInt(cash)).toLocaleString('fr-FR', {
                style: 'currency',
                currency: 'CFA',
            })
        },

        getData() {
            axios.get(this.$store.state.URL_API + 'statDashboard')
                .then((res) => {
                    if (res.data.status) {
                        // console.log(res.data)
                        this.stock = res.data.stock
                        this.presents = res.data.presents
                        this.trades = res.data.trades
                        this.activities = res.data.activities
                        this.initchart('bar')
                    }
                })
                .catch((e) => {
                   console.error(e)
                })
        },

        initchart(type) {
            if (this.myChart != null) {
                this.myChart.destroy()
            }
            this.myChart = new Chart(
                document.getElementById('stats'),
                {
                    type: type,
                    data: {
                        labels: ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'],
                        datasets: [
                            {
                                label: 'Ventes en FCFA',
                                data: this.trades,
                            },
                        ]
                    },
                    options: {
                        responsive: true
                    }
                }
            )
        },

        counthour() {
            setInterval(function () {
                let date = new Date()
                let hour = date.getHours();
                let minutes = date.getMinutes();
                let seconds = date.getSeconds();
                hour = hour < 9 ? '0' + hour : hour
                minutes = minutes < 9 ? '0' + minutes : minutes
                seconds = seconds < 9 ? '0' + seconds : seconds
                let doc = document.getElementById("hour")
                if (doc) {
                    doc.innerHTML = hour + "h" + minutes + "min" + seconds;
                }
            }, 1000);
        }
    },

    mounted() {
        this.getData()
        this.counthour()
    },

    created() {

    }

};

</script>