import { createStore } from 'vuex'

export default createStore({
  state: {
    'URL_API': 'http://divinagroupe-backend.horamagroups.com/api/',
    'URL': 'http://divinagroupe-backend.horamagroups.com',
    // 'URL_API': 'http://127.0.0.1:8000/api/',
    // 'URL': 'http://127.0.0.1:8000',
    'token': localStorage.getItem('token') || '',
    'user': JSON.parse(localStorage.getItem('user')) || null
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
