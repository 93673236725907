<template>
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Présents</h5>

            <!-- Tabs -->
            <ul class="nav nav-tabs nav-tabs-bordered" id="borderedTab" role="tablist">
                <li class="nav-item" role="presentation" v-for="session in presents" :key="session[0]">
                    <button class="nav-link" :class="{ active: activePre == session[0] }" id="all-tab" data-bs-toggle="tab"
                        :data-bs-target="'#bordered-tab-p' + session[0]" type="button" role="tab" aria-controls="all"
                        aria-selected="true" @click="activatePre(session[0])">{{ session[0] }}</button>
                </li>
            </ul>
            <!-- Tabs content -->
            <div class="tab-content pt-2" id="borderedTabContent">
                <div v-for="session in presents" :key="session[0]" class="tab-pane fade"
                    :class="{ 'active show': activePre == session[0] }" :id="'bordered-tab-p' + session[0]" role="tabpanel"
                    aria-labelledby="all-tab">
                    <table class="table datatable">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Nom</th>
                                <th scope="col">Prénom</th>
                                <th scope="col">Session</th>
                                <th scope="col">Ticket</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="sess in session[1]" :key="sess.id">
                                <th scope="row"> {{ sess.number }} </th>
                                <td> {{ sess.employee.first_name }} </td>
                                <td> {{ sess.employee.last_name }} </td>
                                <td>
                                    <a v-if="sess.state == 'Ouverte'" class="btn btn-primary btn-sm"
                                        title="Suspendre la session" @click="updateSession(sess.id, {})">Suspendre <i
                                            class="ri bi-pause-fill"></i></a>
                                    <a v-else-if="sess.state == 'Suspendue'" class="btn btn-success btn-sm"
                                        title="Ouvrir la session" @click="updateSession(sess.id, {})">Ouvrir <i
                                            class="ri bi-play"></i></a>
                                    <a v-if="sess.state != 'Fermée'" class="btn btn-danger btn-sm mx-1"
                                        title="Fermer la session" @click="updateSession(sess.id, { close: true })">Fermer <i
                                            class="ri bi-stop-circle"></i></a>
                                    <span v-else-if="sess.state == 'Fermée'" class="badge bg-danger">Fermée</span>
                                </td>
                                <td>
                                    <div>
                                        <a class="btn btn-primary btn-sm" title="Imprimer le réçu" @click="printTicket(sess)">Imprimer <i
                                                class="bi bi-printer"></i></a>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div><!-- End Bordered Tabs -->

        </div>
    </div>

    <!--  -->

    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Absents</h5>

            <!-- Tabs -->
            <ul class="nav nav-tabs nav-tabs-bordered" id="borderedTab" role="tablist">
                <li class="nav-item" role="presentation" v-for="session in absents" :key="session[0]">
                    <button class="nav-link" :class="{ active: activeAbs == session[0] }" id="allabs-tab"
                        data-bs-toggle="tab" :data-bs-target="'#bordered-tab-a' + session[0]" type="button" role="tab"
                        aria-controls="allabs" aria-selected="true" @click="activateAbs(session[0])">{{ session[0]
                        }}</button>
                </li>
            </ul>
            <!-- Tabs content -->
            <div class="tab-content pt-2" id="borderedTabContent">
                <div v-for="session in absents" :key="session[0]" class="tab-pane fade"
                    :class="{ 'active show': activeAbs == session[0] }" :id="'bordered-tab-a' + session[0]" role="tabpanel"
                    aria-labelledby="all-tab">
                    <table class="table datatable">
                        <thead>
                            <tr>
                                <th scope="col">Nom</th>
                                <th scope="col">Prénom</th>
                                <th scope="col">Session</th>
                                <th scope="col">Reçcu</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="sess in session[1]" :key="sess.id">
                                <td> {{ sess.first_name }} </td>
                                <td> {{ sess.last_name }} </td>
                                <td>
                                    <a @click="createSession(sess.id)" class="btn btn-success btn-sm"
                                        title="Ouvrir la session">Ouvrir <i class="bi bi-play"></i></a>
                                </td>
                                <td>
                                    <a class="btn btn-primary btn-sm" title="Voir le réçu">Voir <i
                                            class="bi bi-eye"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

import axios from 'axios'

export default {
    name: 'PersonnelToday',

    data() {
        return {
            absents: [],
            presents: [],
            activePre: '',
            activeAbs: '',
        }
    },

    mounted() {
        this.getData()
    },

    methods: {

        activatePre(mess) {
            this.activePre = mess
        },

        activateAbs(mess) {
            this.active = mess
        },

        getData() {
            axios.post(this.$store.state.URL_API + 'getSessions', { date: null })
                .then((res) => {
                   // console.log(res)
                    if (res.data.status) {
                        this.presents = res.data.presents
                        this.absents = res.data.absents

                        if(this.activePre == [''])
                            this.activePre = this.presents[0][0]
                        if(this.activeAbs == [''])
                            this.activeAbs = this.absents[0][0]
                    }
                })
                .catch((e) => {
                   console.error(e)
                })
        },

        createSession(id) {
            axios.post(this.$store.state.URL_API + 'createSession', { employee_id: id })
                .then((res) => {
                   // console.log(res)
                    if (res.data.status) {
                        this.getData()
                    }
                })
                .catch((e) => {
                   console.error(e)
                })
        },

        updateSession(id, data) {
            axios.post(this.$store.state.URL_API + 'updateSession/' + id, data)
                .then((res) => {
                   // console.log(res)
                    if (res.data.status) {
                        this.getData()
                    }
                })
                .catch((e) => {
                   console.error(e)
                })
        },

        printTicket(sess) {
            console.log(sess)
            let a = window.open(
                "",
                "null",
                "height=200,width=200,toolbar=0, location=0, status=0, scrollbars=0, resizable=0"
            );
            a.document.write("<html><head>");
            a.document.write(
                "</head><body>"
            );
            a.document.write(`<div>
      <b style='display:block;'>------------------------------</b>
      <b style='margin-left:0px'>Divina Groupe</b>
      <b style='display:block;'>------------------------------</b>
      <b style='font-size:15px;margin-left:0px'> </i> N° ${sess.number} </i> </b>
      </div>`);
            a.document.write(`<br><br><div>
            <b style='font-size:1em;'>Bon service !</b>
            </div>`);
            // a.document.write(`<div>
            //   ${imageContent.innerHTML}
            // </div>`);
            a.document.write("</body></html>");

            setTimeout(() => {
                a.print();
                a.close();
                this.toprint = null
            }, 1000);
        }
    }
}

</script>