<template>
    <section class="section dashboard">

        <div class="row ">
            <div class="col-lg-4 hand" @click="alertParent('global')">
                <div class="card bg-primary">
                    <div class="card-body">

                        <div>
                            <h5 class="card-title text-white">Rapport Global</h5>
                        </div>
                        <ul class="text-white">
                            <li>Emprunt total: <span class="text-warning" style="font-weight: bold;">{{
                                cashFormat(global.total_emp) }}</span>
                            </li>
                            <li>Prêt total: <span class="text-warning" style="font-weight: bold;">{{
                                cashFormat(global.total_pre) }}</span>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>

            <div class="col-lg-4 hand" v-for="fournisseur in this.fournisseurs" :key="fournisseur.id" @click="alertParent('detail', fournisseur.id)">
                <div class="card">
                    <div class="card-body">

                        <div>
                            <h5 class="card-title">{{ fournisseur.last_name }} {{ fournisseur.first_name }}</h5>
                        </div>

                        <ul>
                            <li>Emprunt total: <span class="text-danger" style="font-weight: bold;">{{
                                cashFormat(fournisseur.total_emp) }} </span>
                            </li>
                            <li>Prêt total : <span class="text-success" style="font-weight: bold;">{{
                                cashFormat(fournisseur.total_pre) }}
                                </span> </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    </section>
</template>

<script>


import axios from 'axios';

export default {
    name: 'TransactionComponent',
    components: {

    },

    data() {
        return {
            fournisseurs: [],
            global: { total_emp: 0, total_pre: 0 }
        }
    },

    mounted() {
        this.getData()
    },

    created() {

    },

    methods: {
        alertParent(mess, id){
            id ? this.$emit("transactionviewchange", mess, id) : this.$emit("transactionviewchange", mess)
            
        },

        cashFormat(cash) {
            return (parseInt(cash)).toLocaleString('fr-FR', {
                style: 'currency',
                currency: 'CFA',
            })
        },

        getData() {
            axios.get(this.$store.state.URL_API + 'getTransactionContent')
                .then((res) => {
                    if (res.data.status) {
                        this.fournisseurs = res.data.data
                        this.global = res.data.global
                        // console.log("FOURNISSEURS: ", res.data.data)
                    }

                })
                .catch((e) => {
                   console.error(e)
                })
        },
    }
};

</script>