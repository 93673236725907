
<template>
    <TopBar p_active="per" />

    <main id="main" class="main">

        <div class="pagetitle">

            <h1 class="text-center my-11 bg-info text-light card-title">Espace gestion du personnel</h1>

        </div><!-- End Page Title -->

        <!-- Tabs -->
        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <!-- Aujourd'hui -->
            <li class="nav-item" role="presentation">
                <button @click="this.switch('today')" class="nav-link active" id="pills-today-tab" data-bs-toggle="pill"
                    data-bs-target="#pills-today" type="button" role="tab" aria-controls="pills-today"
                    aria-selected="true">Aujourd'hui</button>
            </li>
            <!-- Registre des présences -->
            <li class="nav-item" role="presentation">
                <button @click="this.switch('register')" class="nav-link" id="pills-register-tab" data-bs-toggle="pill"
                    data-bs-target="#pills-register" type="button" role="tab" aria-controls="pills-register"
                    aria-selected="false">Registre des
                    presences</button>
            </li>
            <!-- Emplyées -->
            <li class="nav-item" role="presentation">
                <button @click="this.switch('emp')" class="nav-link" id="pills-option-tab" data-bs-toggle="pill"
                    data-bs-target="#pills-option" type="button" role="tab" aria-controls="pills-option"
                    aria-selected="false">Employés</button>
            </li>
            <!-- Stats -->
            <li class="nav-item" role="presentation">
                <button @click="this.switch('stats')" class="nav-link" id="pills-stats-tab" data-bs-toggle="pill"
                    data-bs-target="#pills-stats" type="button" role="tab" aria-controls="pills-stats"
                    aria-selected="false">Statistiques</button>
            </li>
        </ul>
        <div class="tab-content pt-2" id="myTabContent">
            <div class="tab-pane fade show active" id="pills-today" role="tabpanel" aria-labelledby="today-tab">
                <PersonnelToday v-if="this.screen == 'today'" />
            </div>
            <div class="tab-pane fade" id="pills-register" role="tabpanel" aria-labelledby="register-tab">
                <RegisterPresence v-if="this.screen == 'register'" />
            </div>
            <div class="tab-pane fade" id="pills-stats" role="tabpanel" aria-labelledby="stats-tab">
                <StatStockComponent v-if="this.screen == 'stats'" />
            </div>
            <div class="tab-pane fade" id="pills-option" role="tabpanel" aria-labelledby="option-tab">
                <div v-if="this.screen == 'emp'">
                    <EmployeeComponent v-if="employee_display == 'main'" @employeeviewchange="changesemployeeview" />
                    <EmployeeCreateComponent v-else-if="employee_display == 'create'"
                        @employeeviewchange="changesemployeeview" />
                </div>

            </div>
        </div>

    </main><!-- End #main -->

    <FootBar />
</template>

<script>

import TopBar from '@/components/layouts/top.vue'
import FootBar from '@/components/layouts/foot.vue'
import PersonnelToday from '@/components/personnels/today'
import RegisterPresence from '@/components/personnels/registerpresence'
import EmployeeComponent from '@/components/personnels/employee.vue'
import EmployeeCreateComponent from '@/components/personnels/employeecreate'
import StatStockComponent from '@/components/personnels/stats.vue'
export default {
    name: 'PersonnelView',

    components: {
        TopBar,
        FootBar,
        PersonnelToday,
        RegisterPresence,
        EmployeeComponent,
        StatStockComponent,
        EmployeeCreateComponent
    },

    data() {
        return {
            screen: 'today',
            employee_display: 'main',
        }
    },

    methods: {
        switch(mess) {
            this.screen = mess
        },

        changesemployeeview(mess) {
            this.employee_display = mess
        }
    }
}


</script>