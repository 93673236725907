<template>
    <!-- ======= Footer ======= -->
    <footer id="footer" class="footer">
        <div class="copyright">
            &copy; Copyright <strong><span>Horama Groups</span></strong>. All Rights Reserved
        </div>

    </footer><!-- End Footer -->
</template>
 
 
<script>

export default {
    'name': 'FootBar'
};

</script>