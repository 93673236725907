<template>
    <button class="btn btn-primary mb-3" @click="alertParent('create')">Ajouter un collaborateur +</button>

    <section class="section">
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">
                            Liste de vos collaborateurs (Fournisseurs et clients)
                        </h5>
                        <!-- Table with stripped rows -->
                        <div class="active">
                            <table class="table datatable ">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Nom</th>
                                        <th scope="col">Prénoms</th>
                                        <th scope="col">Contact</th>
                                        <th scope="col">Type</th>
                                        <th scope="col">Date de création</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="collab in collabs" :key="collab.id">
                                        <th scope="row">{{ collab.id }}</th>
                                        <td>{{ collab.last_name }}</td>
                                        <td>{{ collab.first_name }}</td>
                                        <td>{{ collab.phone }}</td>
                                        <td><span :class="{'bg-info': collab.type == 'Fournisseur', 'bg-warning': collab.type == 'Client'}" class="badge">{{ collab.type }}</span></td>
                                        <td>{{ collab.created_at.slice(0, 10) }}</td>
                                        <td>
                                            <div>
                                                <a class="btn btn-primary btn-sm" data-bs-toggle="modal"
                                                    :data-bs-target="'#mod' + collab.id" title="Supprimer"><i
                                                        class="bi bi-pencil"></i></a>
                                                <a class="btn btn-danger btn-sm mx-1" data-bs-toggle="modal"
                                                    :data-bs-target="'#sup' + collab.id" title="Supprimer"><i
                                                        class="bi bi-trash"></i></a>
                                            </div>

                                            <!-- Suppression modal -->
                                            <div class="modal fade" :id="'sup' + collab.id" tabindex="-1">
                                                <div class="modal-dialog modal-dialog-centered">
                                                    <div class="modal-content">
                                                        <div class="modal-header">
                                                            <h5 class="modal-title text-center">
                                                                Confirmer en entrant votre code de sécurité
                                                            </h5>
                                                            <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                                aria-label="Close"></button>
                                                        </div>
                                                        <div class="modal-body">
                                                            <!-- No Labels Form -->
                                                            <form class="row g-3">
                                                                <div class="col-md-6">
                                                                    <input type="password" class="form-control"
                                                                        placeholder="******" v-model="this.inputcode" />
                                                                </div>
                                                            </form>
                                                            <!-- End No Labels Form -->
                                                        </div>
                                                        <div class="modal-footer">
                                                            <button type="button" class="btn btn-primary"
                                                                data-bs-dismiss="modal">
                                                                Annuler
                                                            </button>
                                                            <button type="button" class="btn btn-danger"
                                                                data-bs-dismiss="modal" @click="deleteCollab(collab.id)">
                                                                Je confirme
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Modification modal -->
                                            <div class="modal fade" :id="'mod' + collab.id" tabindex="-1">
                                                <div class="modal-dialog modal-dialog-centered">
                                                    <div class="modal-content">
                                                        <div class="modal-header">
                                                            <h5 class="modal-title text-center">
                                                                Modification
                                                            </h5>
                                                            <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                                aria-label="Close"></button>
                                                        </div>
                                                        <div class="modal-body">
                                                            <!-- No Labels Form -->
                                                            <form class="row g-3">
                                                                <div class="col-md-6">
                                                                    <input type="email" class="form-control"
                                                                        :placeholder="collab.last_name"
                                                                        v-model="upCollab.last_name" />
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <input type="text" class="form-control"
                                                                        :placeholder="collab.first_name"
                                                                        v-model="upCollab.first_name" />
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <input type="text" class="form-control"
                                                                        :placeholder="collab.phone"
                                                                        v-model="upCollab.phone" />
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <select id="inputState" class="form-select"
                                                                        v-model="upCollab.type">
                                                                        <option value="">
                                                                            --Type de collaboratuer--
                                                                        </option>
                                                                        <option value="Fournisseur">Fournisseur</option>
                                                                        <option value="Client">Client</option>
                                                                    </select>
                                                                </div>
                                                            </form>
                                                            <!-- End No Labels Form -->
                                                        </div>
                                                        <div class="modal-footer">
                                                            <button type="button" class="btn btn-danger"
                                                                data-bs-dismiss="modal">
                                                                Annuler
                                                            </button>
                                                            <button type="button" class="btn btn-success"
                                                                data-bs-dismiss="modal" @click="updateCollab(collab.id)">
                                                                Enregistrer
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- End Table with stripped rows -->
                    </div>
                </div>
            </div>
        </div>
    </section>
    <vue-basic-alert :duration="300" :closeIn="1500" ref="alert" />

</template>
    
<script>

import axios from 'axios'

export default {
    name: 'CollabComponent',
    components: {
    },

    data() {
        return {
            collabs: [],
            upCollab: {
                last_name: null,
                first_name: null,
                phone: null,
                type: '',
                inputcode: '',
            }
        }
    },

    created() {

    },

    mounted() {
        this.getData()
    },

    methods: {
        alertParent(mess) {
            this.$emit('collabviewchange', mess)
        },

        getData() {
            axios.post(this.$store.state.URL_API + "getCollaborators")
                .then((res) => {
                    // console.log("COLLABORATEURS: ", res.data)
                    if (res.data.status) {
                        this.collabs = res.data.data
                    }
                })
                .catch((e) => {
                   console.error(e)
                })
        },

        deleteCollab(id) {
            axios.post(this.$store.state.URL_API + "deleteCollaborator/" + id, {code: this.inputcode})
                .then((res) => {
                   // console.log(res)
                    if (res.data.status) {
                        this.getData()
                        this.inputcode = ''
                    } else{
                        this.$refs.alert
                            .showAlert(
                                'error',
                                res.data.message,
                                "Désolé",
                            )
                    }
                })
                .catch((e) => {
                   console.error(e)
                })
        },

        updateCollab(id) {
            axios.post(this.$store.state.URL_API + "updateCollaborator/" + id, this.upCollab)
                .then((res) => {
                    // console.log("UPDATE: ", res.data)
                    if (res.data.status) {
                        this.getData()
                        this.upCollab = {
                            last_name: null,
                            first_name: null,
                            phone: null,
                            type: ''
                        }
                    }
                })
                .catch((e) => {
                   console.error(e)
                })
        }
    }
}

</script>