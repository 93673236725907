<template>
    <section class="section dashboard">

        <div class="row">
            <div class="col-12">
                <div class="card bg-light">

                    <div class="filter">
                        <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></a>
                        <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                            <li class="dropdown-header text-start">
                                <h6>Filtre</h6>
                            </li>

                            <li class="dropdown-item hand" @click="getData('w')">Par semiane</li>
                            <li class="dropdown-item hand" @click="getData('m')">Par mois</li>
                            <li class="dropdown-item hand" @click="getData('y')">Pour cette année</li>
                        </ul>
                    </div>

                    <div class="card-body">
                        <h5 class="card-title">Statistiques des ventes</h5>
                        <div class="row col-9" v-if="filter != 'y'">
                            <div class="col-2">
                            <input type="date" class="form-control" v-model="date" @change="getData(filter)">
                        </div>
                        <div class="btn btn-danger col-1" @click="resetData()"> <i class="bi bi-arrow-repeat"></i> </div>
                        </div>
                        


                        <!-- Line Chart -->
                        <div>
                            <canvas id="stats"></canvas>
                        </div>
                        <!-- End Line Chart -->

                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'
import Chart from 'chart.js/auto'

export default {
    name: 'StatVenteComponent',
    component: {

    },
    data() {
        return {
            trades: [],
            labels: [],
            filter: 'w',
            date: null,
            myChart: null
        }
    },

    mounted() {
        this.getData('w')
    },

    created() {

    },

    methods: {

        getData(filter) {
            this.filter = filter
            axios.post(this.$store.state.URL_API + 'statTrades', { filter: this.filter, date: this.date })
                .then((res) => {
                    if (res.data.status) {
                       // console.log(res)
                        this.trades = res.data.trades
                        this.labels = res.data.labels
                        this.initchart('bar')
                    }
                })
                .catch((e) => {
                   console.error(e)
                })
        },

        resetData(){
            this.date = null
            this.getData(this.filter)
        },

        initchart(type) {
            if (this.myChart != null) {
                this.myChart.destroy()
            }
            this.myChart = new Chart(
                document.getElementById('stats'),
                {
                    type: type,
                    data: {
                        labels: this.labels,
                        datasets: [
                            {
                                label: 'Ventes en FCFA',
                                data: this.trades
                            },
                        ]
                    },
                    options: {
                        // responsive: true,
                        height: 50
                    }
                }
            )
        }


    }

}


</script>