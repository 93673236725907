import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '@/views/auth/login'
import NotFoundView from '@/views/auth/notfound'
import DashboardView from '@/views/dashboard.vue'

import VenteView from '@/views/tabs/vente';
import PersonnelView from '@/views/tabs/personnel';
import StockView from '@/views/tabs/stock';
import ParametrageView from '@/views/tabs/parametrage';


const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },

  {
    path: '/notfound',
    name: 'notfound',
    component: NotFoundView
  },

  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView
  },

  {
    path: '/ventes',
    name: 'ventes',
    component: VenteView
  },

  {
    path: '/personnel',
    name: 'personnels',
    component: PersonnelView
  },

  {
    path: '/stock',
    name: 'stocks',
    component: StockView
  },

  {
    path: '/parametrage',
    name: 'parametrage',
    component: ParametrageView
  },
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
