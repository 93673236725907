<template>
    <div class="row">
        <!-- Filter -->
        <div class="card col-7">
            <div class="card-body">
                <h5 class="card-title">Choisir une periode</h5>

                <form @submit.prevent="getData()">
                    <div class="row mb-3">
                        <div class="col-3">
                            <input type="date" class="form-control" v-model="period.date_start" required>
                        </div>
                        <div class="col-3">
                            <input type="date" class="form-control" :min="period.date_start" v-model="period.date_end" required>
                        </div>
                        <button type="submit" class="btn btn-primary col-4 mx-2">Procéder au filtre <span v-if="load"
                                class="spinner-border spinner-border-sm"></span> </button>
                        <div class="btn btn-danger col-1" @click="resetData()"> <i class="bi bi-arrow-repeat"></i> </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- Total -->
        <div class="card bg-warning col-4 mx-2">
            <div class="card-body">

                <div>
                    <h5 class="card-title text-white">Total vendu <br>
                        <span class="text-primary" style="font-weight: bold; font-size: 25px; padding: 0;">
                            {{ cashFormat(total_amount) }}
                        </span>
                    </h5>
                </div>

            </div>
        </div>
    </div>

    <!-- Liste des ventes-->
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Registre de toutes les ventes enregistrées</h5>

            <!-- Table with stripped rows -->
            <div class="active">
                <table class="table datatable">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Date</th>
                            <th scope="col">Heure</th>
                            <th scope="col">Montant (en FCFA)</th>
                            <th scope="col">Reçu</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="trade in trades" :key="trade.id">
                            <th scope="row">n° {{ trade.trade_number }} </th>
                            <td> {{ trade.created_at.slice(0, 10) }} </td>
                            <td> {{ trade.created_at.slice(11, 16) }} </td>
                            <td> {{ cashFormat(trade.amount) }} </td>
                            <td>
                                <a class="btn btn-danger btn-sm" title="Supprimer l'achat" data-bs-toggle="modal"
                                    :data-bs-target="'#suptrade' + trade.id"><i class="bi bi-trash"></i></a>

                                <!-- Delete modal -->
                                <div class="modal fade" :id="'suptrade' + trade.id" tabindex="-1">
                                    <div class="modal-dialog modal-dialog-centered">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title text-center">
                                                    Confirmer en entrant votre code de sécurité
                                                </h5>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                    aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body">
                                                <!-- No Labels Form -->
                                                <form class="row g-3">
                                                    <div class="col-md-6">
                                                        <input type="password" class="form-control" placeholder="******"
                                                            v-model="inputcode" />
                                                    </div>
                                                </form>
                                                <!-- End No Labels Form -->
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
                                                    Annuler
                                                </button>
                                                <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                                                    @click="deleteTrade(trade.id)">
                                                    Je confirme
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- End Table with stripped rows -->

        </div>
    </div>
    <vue-basic-alert :duration="300" :closeIn="1500" ref="alert" />
</template>

<script>

import axios from 'axios'

export default {
    name: 'RegisterSellComponent',

    components: {

    },

    data() {
        return {
            trades: null,
            period: { date_start: null, date_end: null },
            total_amount: null,
            inputcode: '',
            load: false
        }
    },

    created() {

    },

    mounted() {
        this.getData()
    },

    methods: {

        cashFormat(cash) {
            return (parseInt(cash)).toLocaleString('fr-FR', {
                style: 'currency',
                currency: 'CFA',
            })
        },

        getData() {
            this.load = true
            axios.post(this.$store.state.URL_API + 'getTrades', this.period)
                .then((res) => {
                    this.total_amount = 0
                    if (res.data.status) {
                       // console.log(res)
                        this.trades = res.data.data
                        for (let index = 0; index < this.trades.length; index++) {
                            const element = this.trades[index];
                            this.total_amount += parseInt(element.amount)
                        }
                        this.load = false
                    }
                })
                .catch((e) => {
                   console.error(e)
                    this.load = false
                })
        },

        deleteTrade(id) {
            axios.post(this.$store.state.URL_API + 'deleteTrade/' + id, { code: this.inputcode })
                .then((res) => {
                    this.total_amount = 0
                    if (res.data.status) {
                       // console.log(res)
                        this.getData()
                        this.inputcode = ""
                        this.$refs.alert
                            .showAlert(
                                'success',
                                "",
                                "Supprimé avec succès",
                            )
                    } else {
                        this.$refs.alert
                            .showAlert(
                                'error',
                                res.data.message,
                                "Désolé",
                            )
                    }
                })
                .catch((e) => {
                   console.error(e)
                    this.$refs.alert
                        .showAlert(
                            'error',
                            e.response.message,
                            "Désolé",
                        )
                })
        },

        resetData() {
            this.period = { date_start: null, date_end: null };
            this.getData()
        }
    }
}


</script>