<template>
    <section class="section dashboard">

        <!-- Opérations -->
        <div class="row">
            <div class="col-12">
                <div class="card bg-light">

                    <div class="filter">
                        <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></a>
                        <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                            <li class="dropdown-header text-start">
                                <h6>Filtre</h6>
                            </li>
                            <li class="dropdown-item hand" @click="applyFilter('w')">Par semaine</li>
                            <li class="dropdown-item hand" @click="applyFilter('m')">Par mois</li>
                            <li class="dropdown-item hand" @click="applyFilter('y')">Cette année</li>
                        </ul>
                    </div>

                    <div class="card-body">
                        <ul class="nav nav-tabs nav-tabs-bordered mt-2 mb-3" id="pills-tab" role="tablist">
                            <li class="nav-item">
                                <button @click="switchchart('op')" class="nav-link active" id="pills-operation-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-operation" type="button" role="tab"
                                    aria-controls="pills-operation" aria-selected="true">Statistiques des opérations</button>
                            </li>
                            <li class="nav-item">
                                <button @click="switchchart('tr')" class="nav-link" id="pills-transactions-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-transactions" type="button" role="tab"
                                    aria-controls="pills-transactions" aria-selected="true">Statistiques des transactions</button>
                            </li>
                        </ul>
                        <div class="row col-9" v-if="filter != 'y'">
                            <div class="col-2">
                            <input type="date" class="form-control" v-model="date" @change="applydatechange()">
                        </div>
                        <div class="btn btn-danger col-1" @click="resetData()"> <i class="bi bi-arrow-repeat"></i> </div>
                        </div>
                        <!-- Line Chart -->
                        <div v-if="chart=='op'">
                            <canvas id="statop"></canvas>
                        </div>
                        <div v-else>
                            <canvas id="stattr"></canvas>
                        </div>
                        <!-- End Line Chart -->

                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'
import Chart from 'chart.js/auto'

export default {
    name: 'StatStockComponent',
    component: {

    },
    data() {
        return {
            chart: 'op',
            myChartOp: null,
            myChartTr: null,
            incomes: [],
            outcomes: [],
            prets: [],
            emprunts: [],
            filter: 'w',
            date: null,
            labelsOp: [],
            labelsTrans: []
        }
    },

    mounted() {
        this.getDataOp('w')
        this.getDataTr('w')
    },

    created() {

    },

    methods: {

        getDataOp(filter) {
            this.filter = filter
            axios.post(this.$store.state.URL_API + 'statStockOp', { filter: this.filter, date: this.date })
                .then((res) => {
                    if (res.data.status) {
                       // console.log(res)
                        this.incomes = res.data.incomes
                        this.outcomes = res.data.outcomes
                        this.labelsOp = res.data.labels
                        this.initchartop('bar')
                    }
                })
                .catch((e) => {
                   console.error(e)
                })
        },

        getDataTr(filter) {
            this.filter = filter
            axios.post(this.$store.state.URL_API + 'statStockTrans', { filter: this.filter, date: this.date })
                .then((res) => {
                    if (res.data.status) {
                       // console.log(res)
                        this.prets = res.data.prets
                        this.emprunts = res.data.emprunts
                        this.labelsTrans = res.data.labels
                        this.initcharttr('bar')
                    }
                })
                .catch((e) => {
                   console.error(e)
                })
        },

        initchartop(type) {

            if (this.myChartOp != null) {
                this.myChartOp.destroy()
            }
            this.myChartOp = new Chart(
                document.getElementById('statop'),
                {
                    type: type,
                    data: {
                        labels: this.labelsOp,
                        datasets: [
                            {
                                label: 'Sacs entrés',
                                data: this.incomes,
                            },
                            {
                                label: 'Sacs sortis',
                                data: this.outcomes,
                            }
                        ]
                    },
                    options: {
                        responsive: true
                    }
                }
            )

        },

        initcharttr(type) {

            if (this.myChartTr != null) {
                this.myChartTr.destroy()
            }
            this.myChartTr = new Chart(
                document.getElementById('stattr'),
                {
                    type: type,
                    data: {
                        labels: this.labelsTrans,
                        datasets: [
                            {
                                label: 'Prêts',
                                data: this.prets,
                            },
                            {
                                label: 'Emprunts',
                                data: this.emprunts,
                            }
                        ]
                    },
                    options: {
                        responsive: true
                    }
                }
            )

        },

        switchchart(ch){
            if(ch == 'op'){
                this.chart = 'op'
                this.getDataOp(this.filter)
            }else{
                this.chart = 'tr'
                this.getDataTr(this.filter)
            }
        },

        applyFilter(fil){
            if(this.chart == 'op'){
                this.getDataOp(fil)
            }else{
                this.getDataTr(fil)
            }
        },

        applydatechange(){
            if(this.chart == 'op'){
                this.getDataOp(this.filter)
            }else{
                this.getDataTr(this.filter)
            }
        },

        resetData(){
            this.date = null
            this.applydatechange();
        },


    }

}


</script>