<template>
    <div class="row">
        <!-- Nouveau poste -->
        <div class="col-lg-6">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Ajouter un poste</h5>
                    <form @submit.prevent="createPost()">
                        <div class="row mt-3">
                            <div class="col-6">
                                <input v-model="this.title" type="text" class="form-control" id="inputText"
                                    placeholder="Intitulé..">
                            </div>

                            <button type="submit" class="btn btn-primary col-2">Ajouter +</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- Nouveau mot de passe -->
        <div class="col-lg-6">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title text-danger">*Mot de passe de connexion*</h5>
                    <form @submit.prevent="updateUserPassword()">
                        <div class="row mt-3">
                            <div class="col-md-3">
                                <input v-model="securitycode.old_password" type="password" class="form-control"
                                    placeholder="Ancien mdp..." minlength="5" required>
                            </div>
                            <div class="col-md-3">
                                <input v-model="securitycode.new_password" type="password" class="form-control"
                                    placeholder="Nouveau mdp..." minlength="5" required>
                            </div>
                            <div class="col-md-3">
                                <input type="submit" class="btn btn-primary" value="Modifier" />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <!-- Liste des postes -->
    <div class="col-6">
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Liste des postes</h5>

                <div class="active">
                    <table class="table table-sm datatable">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Intitulé</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="post in posts" :key="post.id">
                                <th scope="row"> {{ post.id }} </th>
                                <td> {{ post.title }} </td>
                                <td>
                                    <div>
                                        <a class="btn btn-primary btn-sm" data-bs-toggle="modal"
                                            :data-bs-target="'#mod' + post.id" title="Supprimer"><i
                                                class="bi bi-pencil"></i></a>
                                        -
                                        <a class="btn btn-danger btn-sm" data-bs-toggle="modal"
                                            :data-bs-target="'#sup' + post.id" title="Supprimer"><i
                                                class="bi bi-trash"></i></a>
                                    </div>

                                    <!-- Delete modal -->
                                    <div class="modal fade" :id="'sup' + post.id" tabindex="-1">
                                        <div class="modal-dialog modal-dialog-centered">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title text-center">
                                                        Confirmer en entrant votre code de sécurité
                                                    </h5>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                        aria-label="Close"></button>
                                                </div>
                                                <div class="modal-body">
                                                    <!-- No Labels Form -->
                                                    <form class="row g-3">
                                                        <div class="col-md-6">
                                                            <input type="password" class="form-control" placeholder="******"
                                                                v-model="this.inputcode" focus />
                                                        </div>
                                                    </form>
                                                    <!-- End No Labels Form -->
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
                                                        Annuler
                                                    </button>
                                                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                                                        @click="deletePost(post.id)">
                                                        Je confirme
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Update modal -->
                                    <div class="modal fade" :id="'mod' + post.id" tabindex="-1">
                                        <div class="modal-dialog modal-dialog-centered">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title text-center">
                                                        Modification
                                                    </h5>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                        aria-label="Close"></button>
                                                </div>
                                                <div class="modal-body">
                                                    <!-- No Labels Form -->
                                                    <form class="row g-3">
                                                        <div class="">
                                                            <input type="text" class="form-control"
                                                                :placeholder="post.title" v-model="updateTitle" />
                                                        </div>
                                                    </form>
                                                    <!-- End No Labels Form -->
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
                                                        Annuler
                                                    </button>
                                                    <button type="button" class="btn btn-success" data-bs-dismiss="modal"
                                                        @click="updatePost(post.id)">
                                                        Confirmer
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <vue-basic-alert :duration="300" :closeIn="2500" ref="alert" />
</template>
    
<script>

import axios from 'axios'

export default {
    name: 'SecondParamComponent',
    components: {
    },

    data() {
        return {
            inputcode: '',
            title: '',
            updateTitle: '',
            posts: [],
            securitycode: { old_password: '', new_password: '' }
        }
    },

    created() {

    },

    mounted() {
        this.getPosts()
    },

    methods: {
        alertParent(mess) {
            this.$emit('collabviewchange', mess)
        },

        cashFormat(cash) {
            return (parseInt(cash)).toLocaleString('fr-FR', {
                style: 'currency',
                currency: 'CFA',
            })
        },

        getPosts() {
            axios.get(this.$store.state.URL_API + 'getPosts')
                .then((res) => {
                   // console.log(res)
                    if (res.data.status) {
                        this.posts = res.data.data
                    }
                })
                .catch((e) => {
                   console.error(e)
                })
        },

        createPost() {
            axios.post(this.$store.state.URL_API + 'createPost', { title: this.title })
                .then((res) => {
                   // console.log(res)
                    if (res.data.status) {
                        this.title = ''
                        this.getPosts()
                    }
                })
                .catch((e) => {
                   console.error(e)
                })
        },

        updatePost(id) {
            axios.post(this.$store.state.URL_API + 'updatePost/' + id, { title: this.updateTitle })
                .then((res) => {
                   // console.log(res)
                    if (res.data.status) {
                        this.updateTitle = ''
                        this.getPosts()
                    }
                })
                .catch((e) => {
                   console.error(e)
                })
        },

        updateUserPassword() {
            axios.post(this.$store.state.URL_API + 'updateUserPassword', this.securitycode)
                .then((res) => {
                   // console.log(res)
                    if (res.data.status) {
                        this.securitycode = { old_password: '', new_password: '' }
                        this.$refs.alert
                            .showAlert(
                                'success',
                                '',
                                "Modifié avec succès",
                            )
                    } else {
                        this.$refs.alert
                            .showAlert(
                                'error',
                                res.data.message,
                                "Désolé",
                            )
                    }
                })
                .catch((e) => {
                   console.error(e)
                    this.$refs.alert
                            .showAlert(
                                'error',
                                e.response.data.message,
                                "Désolé",
                            )
                })
        },

        deletePost(id) {
            axios.post(this.$store.state.URL_API + 'deletePost/' + id, { code: this.inputcode })
                .then((res) => {
                    this.total_amount = 0
                    if (res.data.status) {
                       // console.log(res)
                        this.getPosts()
                        this.inputcode = ""
                    } else {
                        this.$refs.alert
                            .showAlert(
                                'error',
                                res.data.message,
                                "Désolé",
                            )
                    }
                })
                .catch((e) => {
                   console.error(e)
                    this.$refs.alert
                        .showAlert(
                            'error',
                            e.response.message,
                            "Désolé",
                        )
                })
        },

    }
}

</script>