<template>
    <main>
        <div class="container">

            <section class="section error-404 min-vh-100 d-flex flex-column align-items-center justify-content-center">
                <h1>404</h1>
                <h2>La page que vous cherchez n'existe pas ou vous n'êtes pas connectez.</h2>
                <div @click="this.$emit('login', 'main')">
                    <router-link class="btn" to="/">Se connecter</router-link>
                </div>
                <!-- <img src="@/assets/img/not-found.svg" class="img-fluid py-5" alt="Page Not Found"> -->
                <div class="credits">
                    <!-- All the links in the footer should remain intact. -->
                    <!-- You can delete the links only if you purchased the pro version. -->
                    <!-- Licensing information: https://bootstrapmade.com/license/ -->
                    <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/nice-admin-bootstrap-admin-html-template/ -->
                    Designed by <a href="https://bootstrapmade.com/">HORAMA GROUPS</a>
                </div>
            </section>

        </div>
    </main><!-- End #main -->
</template>


<script>

export default {
    name: 'NotFoundView'
}

</script>