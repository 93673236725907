<template>
    <button class="btn btn-danger mb-3" @click="alertParent('main')">Annuler</button>

    <section class="section">
        <div class="row">

            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Ajouter un nouveau collaborateur</h5>

                    <!-- No Labels Form -->
                    <form class="row g-3" @submit.prevent="this.createCollab()">
                        <div class="col-md-6">
                            <input v-model="newCollab.last_name" type="text" class="form-control" placeholder="Nom"
                                required>
                        </div>
                        <div class="col-md-6">
                            <input v-model="newCollab.first_name" type="text" class="form-control" placeholder="Prénoms"
                                required>
                        </div>
                        <div class="col-md-6">
                            <input v-model="newCollab.phone" type="text" class="form-control" placeholder="Contact"
                                minlength="10" required>
                        </div>
                        <div class="col-md-6">
                            <select id="inputState" class="form-select" v-model="newCollab.type" required>
                                <option value="">--Type de collaborateur--</option>
                                <option value="Fournisseur">Fournisseur</option>
                                <option value="Client">Client</option>
                            </select>
                        </div>
                        <div class="text-center">
                            <div>
                                <button class="btn btn-primary bg-primary" >Enregistrer
                                    <span class="spinner-border spinner-border-sm" v-if="load"></span>
                                </button>
                            </div>
                        </div>
                    </form><!-- End No Labels Form -->

                </div>
            </div>

        </div>
    </section>
</template>
  
<script>

import axios from 'axios';

export default {
    name: 'CollabCreateComponent',
    components: {

    },

    data() {
        return {
            newCollab: {
                last_name: null,
                first_name: null,
                phone: null,
                type: ''
            },
            load: false
        }
    },

    mounted() {

    },

    created() {

    },

    methods: {
        alertParent(mess) {
            this.$emit('collabviewchange', mess)
        },
        
        createCollab() {
            this.load = true
            axios.post(this.$store.state.URL_API + "createCollaborator", this.newCollab)
                .then((res) => {
                    // console.log("CREATION RESULT", res.data)
                    if (res.data.status) {
                        this.newCollab = {
                            last_name: null,
                            first_name: null,
                            phone: null,
                            type: ''
                        }
                        this.load = false
                        this.alertParent('main')
                    }
                })
                .catch((e) => {
                    this.load = false
                   console.error(e)
                })
        }
    }
};

</script>