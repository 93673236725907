
<template>
    <TopBar p_active="sto" />

    <main id="main" class="main">

        <div class="pagetitle">

            <h1 class="text-center my-11 bg-warning text-light card-title">Espace gestion de stock </h1>

        </div><!-- End Page Title -->

        <!-- Tabs -->
        <section class="section dashboard">
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <!-- Aujourd'hui -->
                <li class="nav-item" role="presentation">
                    <button @click="this.switch('monstock')" class="nav-link active" id="pills-today-tab"
                        data-bs-toggle="pill" data-bs-target="#pills-today" type="button" role="tab"
                        aria-controls="pills-today" aria-selected="true">Mon Stock</button>
                </li>
                <!-- Transactions -->
                <li class="nav-item" role="presentation">
                    <button @click="this.switch('transa')" class="nav-link" id="pills-transactions-tab"
                        data-bs-toggle="pill" data-bs-target="#pills-transactions" type="button" role="tab"
                        aria-controls="pills-transactions" aria-selected="false">Transactions</button>
                </li>
                <!-- Collaborateurs -->
                <li class="nav-item" role="presentation">
                    <button @click="this.switch('collab')" class="nav-link" id="pills-collab-tab" data-bs-toggle="pill"
                        data-bs-target="#pills-collab" type="button" role="tab" aria-controls="pills-collab"
                        aria-selected="false">Collaborateurs</button>
                </li>
                <!-- Stats -->
                <li class="nav-item" role="presentation">
                    <button @click="this.switch('stats')" class="nav-link" id="pills-stats-tab" data-bs-toggle="pill"
                        data-bs-target="#pills-stats" type="button" role="tab" aria-controls="pills-stats"
                        aria-selected="false">Statistiques</button>
                </li>
            </ul>
            <div class="tab-content pt-2" id="myTabContent">
                <div class="tab-pane fade show active" id="pills-today" role="tabpanel" aria-labelledby="today-tab">
                    <div v-if="this.screen == 'monstock'">
                        <MonStockComponent @transactionviewchange="changestockview" v-if="stock_display == 'main'" />
                        <OperationComponent @transactionviewchange="changestockview" v-else-if="stock_display == 'newop'" />
                    </div>
                </div>
                <div class="tab-pane fade" id="pills-transactions" role="tabpanel" aria-labelledby="option-tab">
                    <div v-if="this.screen == 'transa'">
                        <TransactionComponent v-on:transactionviewchange="changestransactionview"
                            v-if="tran_display == 'main'" />
                        <TransactionGlobalComonent v-on:transactionviewchange="changestransactionview"
                            v-else-if="tran_display == 'global'" />
                        <TransactionDetailComponent :id="d_id" v-on:transactionviewchange="changestransactionview"
                            v-else-if="tran_display == 'detail'" />
                    </div>
                </div>
                <div class="tab-pane fade" id="pills-collab" role="tabpanel" aria-labelledby="option-tab">
                    <div v-if="this.screen == 'collab'">
                        <CollabComponent v-if="collab_display == 'main'" v-on:collabviewchange="changescollabview" />
                        <CollabCreateComponent v-else-if="collab_display == 'create'"
                            v-on:collabviewchange="changescollabview" />
                    </div>
                </div>
                <div class="tab-pane fade" id="pills-stats" role="tabpanel" aria-labelledby="stats-tab">
                    <StatStockComponent v-if="this.screen == 'stats'" />
                </div>
            </div>
        </section>

    </main><!-- End #main -->

    <FootBar />
    <vue-basic-alert :duration="300" :closeIn="1500" ref="alert" />
</template>

<script>

import TopBar from '@/components/layouts/top.vue'
import FootBar from '@/components/layouts/foot.vue'

import OperationComponent from '@/components/stocks/operation'
import TransactionComponent from '@/components/stocks/transaction'
import TransactionGlobalComonent from '@/components/stocks/transactionglobal'
import TransactionDetailComponent from '@/components/stocks/transactiondetail'
import MonStockComponent from '@/components/stocks/monstock'
import CollabComponent from '@/components/stocks/collab.vue'
import CollabCreateComponent from '@/components/stocks/collabcreate.vue'
import StatStockComponent from '@/components/stocks/stats.vue'

export default {
    name: 'StockView',
    components: {
        TopBar,
        FootBar,
        OperationComponent,
        TransactionComponent,
        TransactionGlobalComonent,
        TransactionDetailComponent,
        MonStockComponent,
        CollabCreateComponent,
        CollabComponent,
        StatStockComponent
    },

    data() {
        return {
            screen: 'monstock',
            tran_display: 'main',
            stock_display: 'main',
            collab_display: 'main',
            d_id: 0
        }
    },

    methods: {
        switch(mess) {
            this.screen = mess
        },

        changestransactionview(mess, id) {
            this.tran_display = mess
            if (id) {
                this.d_id = id
            }
        },

        changestockview(mess) {
            this.stock_display = mess
        },

        changescollabview(mess) {
            this.collab_display = mess
        },
    }
}


</script>