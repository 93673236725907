<template>
    <div class="row">
        <!-- Enregistrer -->
        <div class="card col-7">
            <div class="card-body">
                <h5 class="card-title">Enregistrer une vente</h5>

                <!-- Horizontal Form -->
                <form @submit.prevent="makeTrade">
                    <div class="row mb-3">
                        <div class="col-6">
                            <input type="number" v-model="this.amount" class="form-control" id="inputText" required
                                placeholder="Montant(ex:5000)">
                        </div>

                        <button type="submit" class="btn btn-primary col-2 mx-2">Valider <span v-if="load"
                                class="spinner-border spinner-border-sm"></span> </button>
                        <div class="btn btn-warning col-2" @click="printticket(toprint)" v-if="toprint != null">Imprimer
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <!-- Total -->
        <div class="card bg-warning col-4 mx-2">
            <div class="card-body">

                <div>
                    <h5 class="card-title text-white">Total vendu <br>
                        <span class="text-primary" style="font-weight: bold; font-size: 25px; padding: 0;">
                            {{ cashFormat(total_amount) }}
                        </span>
                    </h5>
                </div>

            </div>
        </div>
    </div>


    <!-- Liste -->
    <div class="row">
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Ventes enregistrées</h5>

                <!-- Table with stripped rows -->
                <div class="active">
                    <table class="table datatable">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Heure</th>
                                <th scope="col">Montant</th>
                                <th scope="col">Reçu</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="trade in trades" :key="trade.id">
                                <th scope="row">n° {{ trade.trade_number }} </th>
                                <td> {{ trade.created_at.slice(11, 16) }} </td>
                                <td> {{ cashFormat(trade.amount) }} </td>
                                <td>
                                    <a class="btn btn-primary btn-sm mx-1" title="Imprimer le réçu"
                                        @click="printticket(trade)">Imprimer
                                        <i class="bi bi-printer"></i></a>
                                    <a class="btn btn-danger btn-sm" title="Voir le réçu" data-bs-toggle="modal"
                                        :data-bs-target="'#suptrade' + trade.id"><i class="bi bi-trash"></i></a>

                                    <!-- Delete modal -->
                                    <div class="modal fade" :id="'suptrade' + trade.id" tabindex="-1">
                                        <div class="modal-dialog modal-dialog-centered">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title text-center">
                                                        Confirmer en entrant votre code de sécurité
                                                    </h5>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                        aria-label="Close"></button>
                                                </div>
                                                <div class="modal-body">
                                                    <!-- No Labels Form -->
                                                    <form class="row g-3">
                                                        <div class="col-md-6">
                                                            <input type="password" class="form-control" placeholder="******"
                                                                v-model="inputcode" />
                                                        </div>
                                                    </form>
                                                    <!-- End No Labels Form -->
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
                                                        Annuler
                                                    </button>
                                                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                                                        @click="deleteTrade(trade.id)">
                                                        Je confirme
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <vue-basic-alert :duration="300" :closeIn="1500" ref="alert" />
</template>

<script>
import axios from 'axios'


export default {
    name: 'NewSellComponent',
    component: {

    },
    data() {
        return {
            amount: null,
            trades: null,
            toprint: null,
            total_amount: null,
            inputcode: '',
            load: false
        }
    },

    mounted() {
        this.getData()
    },

    created() {

    },

    methods: {

        cashFormat(cash) {
            return (parseInt(cash)).toLocaleString('fr-FR', {
                style: 'currency',
                currency: 'CFA',
            })
        },

        getData() {
            axios.get(this.$store.state.URL_API + 'getTradesToday')
                .then((res) => {
                    this.total_amount = 0
                    if (res.data.status) {
                       // console.log(res)
                        this.trades = res.data.data
                        for (let index = 0; index < this.trades.length; index++) {
                            const element = this.trades[index];
                            this.total_amount += parseInt(element.amount)
                        }
                        this.load = false
                    }
                })
                .catch((e) => {
                   console.error(e)
                    this.load = false
                })
        },

        makeTrade() {
            this.load = true
            axios.post(this.$store.state.URL_API + 'createTrade', { amount: this.amount })
                .then((res) => {
                   // console.log(res)
                    if (res.status) {
                        this.toprint = res.data.data
                        this.amount = null
                        this.getData()
                    }
                })
                .catch((e) => {
                   console.error(e)
                    this.load = false
                })
        },

        deleteTrade(id) {
            axios.post(this.$store.state.URL_API + 'deleteTrade/' + id, { code: this.inputcode })
                .then((res) => {
                    this.total_amount = 0
                    if (res.data.status) {
                       // console.log(res)
                        this.getData()
                        this.inputcode = ""
                    } else {
                        this.$refs.alert
                            .showAlert(
                                'error',
                                res.data.message,
                                "Désolé",
                            )
                    }
                })
                .catch((e) => {
                   console.error(e)
                    this.$refs.alert
                        .showAlert(
                            'error',
                            e.response.message,
                            "Désolé",
                        )
                })
        },

        printticket(trade) {
            const price = trade.amount + ' CFA'
            let date = new Date()
                let hour = date.getHours();
                let minutes = date.getMinutes();
                let seconds = date.getSeconds();
                hour = hour < 9 ? '0' + hour : hour
                minutes = minutes < 9 ? '0' + minutes : minutes
                seconds = seconds < 9 ? '0' + seconds : seconds
                const time = hour + "h" + minutes + "min" + seconds;

                var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
                var today = new Date()
                const dt = today.toLocaleDateString("fr-FR", options)
            let a = window.open(
                "",
                "null",
                "height=200,width=500,toolbar=0, location=0, status=0, scrollbars=0, resizable=0"
            );
            // a.moveBy(1200, 1200);
            a.document.write("<html><head>");
            a.document.write(
                "</head><body>"
            );
            a.document.write(`<div>
        <b style='display:block;'><span style='font-size:13px'>${dt} <br> ${time}</span></b><br>
      <b style='display:block;'>------------------------------</b>
      <b style='margin-left:0px' font-size:'45px'>Divina Groupe</b>
      <b style='display:block;'>------------------------------</b><br>
      <b style='margin-left:0px'> </i> ${price} </i> </b>
      </div>`);
            a.document.write(`<br><br><div>
            <b style='font-size:1em;'>Merci !</b>
            </div>`);
            // a.document.write(`<div>
            //   ${imageContent.innerHTML}
            // </div>`);
            a.document.write("</body></html>");

            setTimeout(() => {
                a.print();
                a.close();
                this.toprint = null
            }, 1000);
        }

    }

}


</script>