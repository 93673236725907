<template>
    <button class="btn btn-danger mb-3" @click="alertParent('main')">Annuler</button>
    <div class="row">
        <!-- Enregistrer -->
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Opération sur le stock</h5>

                <!-- Horizontal Form -->
                <form @submit.prevent="makeOperatipn()">
                    <div class="row mb-2">
                        <div class="col-4">
                            <input v-model="operation.bag_number" type="text" class="form-control" id="inputText"
                                placeholder="Nbre sac(ex:5)" required>
                        </div>
                        <div class="col-4">
                            <select v-model="operation.type" class="form-select" aria-label="Default select example">
                                <option value="">-- Opération --</option>
                                <option value="Ajout">Ajouter</option>
                                <option value="Rétrait">Rétirer</option>
                            </select>
                        </div>
                        <button type="submit" class="btn btn-primary col-4">Valider
                            <span class="spinner-border spinner-border-sm" v-if="load"></span>
                        </button>

                    </div>
                </form><!-- End Horizontal Form -->
            </div>
        </div>
    </div>
    <vue-basic-alert :duration="300" :closeIn="1500" ref="alert" />
</template>

<script>

import axios from 'axios';

export default {
    name: 'OperationComponent',

    data() {
        return {
            operation: {
                bag_number: null,
                type: ''
            },
            load: false
        }
    },

    methods: {

        alertParent(mess) {
            this.$emit('transactionviewchange', mess)
        },

        makeOperatipn() {
            this.load = true
            axios.post(this.$store.state.URL_API + 'createOperation', this.operation)
                .then((res) => {
                    if (res.data.status) {
                       // console.log(res)
                        this.alertParent('main')
                    } else {
                        this.$refs.alert
                            .showAlert(
                                'error',
                                res.data.message,
                                "Désolé",
                            )
                    }
                    this.load = false
                })
                .catch((e) => {
                   console.error(e)
                    this.load = false
                    this.$refs.alert
                        .showAlert(
                            'error',
                            e.response.data.message,
                            "Désolé",
                        )
                })
        }
    }
}

</script>