<template>
    <section class="section dashboard">

        <!-- Opérations -->
        <div class="row">
            <div class="col-12">
                <div class="card bg-light">

                    <div class="filter">
                        <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></a>
                        <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                            <li class="dropdown-header text-start">
                                <h6>Modèle de graphe</h6>
                            </li>

                            <li class="dropdown-item hand" @click="initchart('line')">Courbe</li>
                            <li class="dropdown-item hand" @click="initchart('bar')">Histogramme</li>
                        </ul>
                    </div>

                    <div class="card-body">
                        <h5 class="card-title">Statistiques des présences</h5>
                        <div class="col-2">
                            <select v-model="employee" class="form-select" aria-label="Default select example" @change="getData()">
                            <option value="">-- Sélectionner un employé --</option>
                            <option v-for="emp in employees" :value="emp" :key="emp.id">{{ emp.first_name }} {{ emp.last_name
                            }}</option>
                        </select>
                        </div>


                        <!-- Line Chart -->
                        <div>
                            <canvas id="stats"></canvas>
                        </div>
                        <!-- End Line Chart -->

                    </div>

                </div>
            </div>
        </div>

    </section>
</template>

<script>
import axios from 'axios'
import Chart from 'chart.js/auto'

export default {
    name: 'StatPersonnelComponent',
    component: {

    },
    data() {
        return {
            sessions: [],
            employees: [],
            employee: {},
            myChart: null
        }
    },

    mounted() {
        this.getEmployees()
    },

    created() {

    },

    methods: {

        cashFormat(cash) {
            return (parseInt(cash)).toLocaleString('fr-FR', {
                style: 'currency',
                currency: 'CFA',
            })
        },

        getEmployees() {
            axios.get(this.$store.state.URL_API + 'getEmployees')
                .then((res) => {
                    if (res.data.status) {
                       // console.log(res)
                        this.employees = res.data.data
                        if (this.employees.length > 0) {
                            this.employee = this.employees[0]
                            this.getData()
                        }
                    }
                })
                .catch((e) => {
                   console.error(e)
                })
        },

        getData() {
            axios.get(this.$store.state.URL_API + 'statPersonnels/' + this.employee.id)
                .then((res) => {
                    if (res.data.status) {
                       // console.log(res)
                        this.sessions = res.data.sessions
                        this.initchart('bar')
                    }
                })
                .catch((e) => {
                   console.error(e)
                })
        },

        initchart(type) {
            if (this.myChart != null) {
                this.myChart.destroy()
            }
            this.myChart = new Chart(
                document.getElementById('stats'),
                {
                    type: type,
                    data: {
                        labels: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jui', 'Jul', 'Aou', 'Sep', 'Oct', 'Nov', 'Déc'],
                        datasets: [
                            {
                                label: this.employee.first_name,
                                data: this.sessions,
                            },
                        ]
                    },
                    options: {
                        responsive: true
                    }
                }
            )
        }


    }

}


</script>