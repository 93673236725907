
<template>
    <TopBar p_active="par"/>
    <main id="main" class="main">

        <div class="pagetitle">

            <h1 class="text-center my-11 bg-danger mb-3 text-light card-title">Espace parametrage</h1>

        </div>
        
        <FirstParamComponent />
        <SecondParamComponent />

    </main>
    <FootBar />
</template>

<script>

import TopBar from '@/components/layouts/top.vue'
import FootBar from '@/components/layouts/foot.vue'
import FirstParamComponent from '@/components/parametrage/first.vue'
import SecondParamComponent from '@/components/parametrage/second.vue'

export default {
    name: 'ParametrageView',
    components: {
        TopBar,
        FootBar,
        FirstParamComponent,
        SecondParamComponent
    },

    data() {
        return {
            screen: 'today'
        }
    },

    methods: {
        switch(mess) {
            this.screen = mess
        }
    }
}


</script>