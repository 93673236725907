<template>
    <!-- First part -->
    <div class="row">
        <!-- Reserve -->
        <div class="bg-warning col-4">
            <div class="card-body">

                <div>
                    <h5 class="card-title text-white">Nombre de sac en reserve <br>
                        <span class="text-primary" style="font-weight: bold; font-size: 35px; padding: 0;">
                            {{ bags }}
                        </span>
                        -> {{ cashFormat(bags * onbagprice) }}
                    </h5>
                </div>
                <button class="btn btn-secondary" @click="alertParent('newop')">Opération sur le stock +</button>

            </div>
        </div>

        <!-- Resume -->
        <div class="bg-primary col-4">
            <div class="card-body">

                <h5 class="card-title text-white">
                    Sacs sortis ---->
                    <span class="text-warning" style="font-weight: bold; font-size: 20px; padding: 0;">
                        {{ total.bag_s }}
                    </span>
                    <br>
                    <span class="text-warning" style="font-weight: bold; font-size: 20px; padding: 0;">
                        {{ cashFormat(total.bag_s * onbagprice) }}
                    </span>
                </h5>

                <h5 class="card-title text-white p-0 m-0">
                    Sacs entrés ---->
                    <span class="text-warning" style="font-weight: bold; font-size: 20px; padding: 0;">
                        {{ total.bag_e }}
                    </span>
                    <br>
                    <span class="text-warning" style="font-weight: bold; font-size: 20px; padding: 0;">
                        {{ cashFormat(total.bag_e * onbagprice) }}
                    </span>
                </h5>

            </div>
        </div>

        <!-- Filter -->
        <div class="bg-white col-4">
            <div class="card-body">
                <p class="my-2 text-secondary">Choisir une periode</p>

                <form @submit.prevent="getData()">
                    <div class="mb-1">
                        <input type="date" class="form-control" v-model="period.date_start">
                    </div>
                    <div class="mb-1">
                        <input type="date" class="form-control" :min="period.date_start" v-model="period.date_end">
                    </div>
                    <button type="submit" class="btn btn-primary">Procéder au filtre
                        <span class="spinner-border spinner-border-sm" v-if="load"></span>
                    </button>
                    <div type="submit" class="btn btn-danger col-2 mx-1" @click="resetData()"> <i
                            class="bi bi-arrow-repeat"></i> </div>
                </form>

            </div>
        </div>
    </div>

    <div class="row">
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Liste des opérations</h5>
                <div class="active">
                    <table class="table datatable">
                        <thead>
                            <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Heure</th>
                                <th scope="col">Nombre de sac</th>
                                <th scope="col">Type</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="operation in operations" :key="operation.id">
                                <td> {{ operation.created_at.slice(0, 10) }} </td>
                                <td> {{ operation.created_at.slice(11, 16) }} </td>
                                <td> {{ operation.bag_number }} </td>
                                <td> <span
                                        :class="{ 'bg-success': operation.type == 'Ajout', 'bg-secondary': operation.type == 'Rétrait' }"
                                        class="badge">{{ operation.type }}</span> </td>
                                <td><a class="btn btn-danger btn-sm" title="Supprimer l'operation" data-bs-toggle="modal"
                                        :data-bs-target="'#supop' + operation.id"><i class="bi bi-trash"></i></a>

                                    <!-- Delete modal -->
                                    <div class="modal fade" :id="'supop' + operation.id" tabindex="-1">
                                        <div class="modal-dialog modal-dialog-centered">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title text-center">
                                                        Confirmer en entrant votre code de sécurité
                                                    </h5>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                        aria-label="Close"></button>
                                                </div>
                                                <div class="modal-body">
                                                    <!-- No Labels Form -->
                                                    <form class="row g-3">
                                                        <div class="col-md-6">
                                                            <input type="password" class="form-control" placeholder="******"
                                                                v-model="inputcode" />
                                                        </div>
                                                    </form>
                                                    <!-- End No Labels Form -->
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
                                                        Annuler
                                                    </button>
                                                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                                                        @click="deleteOperation(operation.id)">
                                                        Je confirme
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <vue-basic-alert :duration="300" :closeIn="1500" ref="alert" />
</template>

<script>

import axios from 'axios'

export default {
    name: 'MonStockComponent',

    data() {
        return {
            operations: null,
            period: { date_start: null, date_end: null },
            bags: 0,
            onbagprice: 0,
            total: { bag_s: 0, bag_e: 0 },
            inputcode: '',
            load: false
        }
    },

    mounted() {
        this.getData()
    },

    methods: {

        alertParent(mess) {
            this.$emit('transactionviewchange', mess)
        },

        cashFormat(cash) {
            return (parseInt(cash)).toLocaleString('fr-FR', {
                style: 'currency',
                currency: 'CFA',
            })
        },

        getData() {
            this.load = true
            axios.post(this.$store.state.URL_API + 'getOperations', this.period)
                .then((res) => {
                    if (res.data.status) {
                        this.total = { bag_s: 0, bag_e: 0 }
                       // console.log(res)
                        this.operations = res.data.data
                        this.bags = res.data.config.bag_stock
                        this.onbagprice = res.data.config.bag_price
                        for (let index = 0; index < this.operations.length; index++) {
                            const element = this.operations[index];
                            if (element.type == "Ajout") {
                                this.total.bag_e += element.bag_number
                            } else {
                                this.total.bag_s += element.bag_number
                            }

                        }
                        this.load = false
                    }
                })
                .catch((e) => {
                   console.error(e)
                    this.load = false
                })
        },

        deleteOperation(id) {
            axios.post(this.$store.state.URL_API + 'deleteOperation/' + id, { code: this.inputcode })
                .then((res) => {
                    if (res.data.status) {
                       // console.log(res)
                        this.getData()
                        this.inputcode = ""
                    } else {
                        this.$refs.alert
                            .showAlert(
                                'error',
                                res.data.message,
                                "Désolé",
                            )
                    }
                })
                .catch((e) => {
                   console.error(e)
                    this.$refs.alert
                        .showAlert(
                            'error',
                            e.response.message,
                            "Désolé",
                        )
                })

        },

        resetData() {
            this.period = { date_start: null, date_end: null }
            this.getData()
        }
    }
}

</script>