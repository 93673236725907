
<template>
    <TopBar p_active="ven"/>

    <main id="main" class="main">

        <div class="pagetitle">

            <h1 class="text-center my-11 bg-primary text-light card-title">Espace gestion de vente </h1>

        </div><!-- End Page Title -->

        <!-- Tabs -->
        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <!-- Aujourd'hui -->
            <li class="nav-item" role="presentation">
                <button @click="this.switch('today')" class="nav-link active" id="pills-today-tab" data-bs-toggle="pill"
                    data-bs-target="#pills-today" type="button" role="tab" aria-controls="pills-today"
                    aria-selected="true">Aujourd'hui</button>
            </li>
            <!-- Registre des ventes -->
            <li class="nav-item" role="presentation">
                <button @click="this.switch('register')" class="nav-link" id="pills-register-tab" data-bs-toggle="pill"
                    data-bs-target="#pills-register" type="button" role="tab" aria-controls="pills-register"
                    aria-selected="false">Registre des
                    ventes</button>
            </li>
            <!-- Stats -->
            <li class="nav-item" role="presentation">
                <button @click="this.switch('stats')" class="nav-link" id="pills-stats-tab" data-bs-toggle="pill"
                    data-bs-target="#pills-stats" type="button" role="tab" aria-controls="pills-stats"
                    aria-selected="false">Statistiques</button>
            </li>
        </ul>
        <div class="tab-content pt-2" id="myTabContent">
            <div class="tab-pane fade show active" id="pills-today" role="tabpanel" aria-labelledby="today-tab">
                <NewSellComponent v-if="this.screen == 'today'" />
            </div>
            <div class="tab-pane fade" id="pills-register" role="tabpanel" aria-labelledby="register-tab">
                <RegisterSellComponent v-if="this.screen == 'register'" />
            </div>
            <div class="tab-pane fade" id="pills-stats" role="tabpanel" aria-labelledby="stats-tab">
                <StatsVenteComponent v-if="this.screen == 'stats'" />
            </div>
        </div>

    </main><!-- End #main -->

    <FootBar />
</template>

<script>

import TopBar from '@/components/layouts/top.vue'
import FootBar from '@/components/layouts/foot.vue'
import NewSellComponent from '@/components/ventes/newsell'
import RegisterSellComponent from '@/components/ventes/registersell'
import StatsVenteComponent from '@/components/ventes/stats.vue'

export default {
    name: 'VenteView',
    components: {
        TopBar,
        FootBar,
        NewSellComponent,
        RegisterSellComponent,
        StatsVenteComponent
    },

    data() {
        return {
            screen: 'today'
        }
    },

    methods: {
        switch(mess) {
            this.screen = mess
        }
    }
}


</script>