<template>
    <button class="btn btn-danger mb-3" @click="alertParent('main')">Retour</button>
    <div class="row">
        <div class="col-lg-9">
            <div class="card bg-secondary">
                <div class="card-body">

                    <h5 class="card-title text-white">Résumé</h5>
                    <ul class="text-white">
                        <li>Emprunt: <span class="text-warning" style="font-weight: bold;">{{ cashFormat(resume.emp)
                        }}</span>
                            Payé: <span class="text-warning" style="font-weight: bold;">{{ cashFormat(resume.emp_pay)
                            }}</span>
                            Reste à payer: <span class="text-warning" style="font-weight: bold;">{{
                                cashFormat(resume.emp - resume.emp_pay) }}</span>
                        </li>
                        <li>Prêt: <span class="text-warning" style="font-weight: bold;">{{ cashFormat(resume.pre)
                        }}</span>
                            Payé: <span class="text-warning" style="font-weight: bold;">{{ cashFormat(resume.pre_pay)
                            }}</span>
                            Reste à payer: <span class="text-warning" style="font-weight: bold;">{{
                                resume.pre - resume.pre_pay < 0 ? cashFormat(0) : cashFormat(resume.pre - resume.pre_pay)
                            }}</span>
                        </li>
                    </ul>

                </div>
            </div>
        </div>
    </div>

    <section class="section">
        <div class="row">
            <div class="">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">
                            Liste globale des transaction
                        </h5>
                        <!-- Table with stripped rows -->
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Date de création</th>
                                    <th scope="col">Collaborateur</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Intitulé</th>
                                    <th scope="col text-primary">Montant à remboursser</th>
                                    <th scope="col text-success">Montant rembourssé</th>
                                    <th scope="col text-danger">Reste à payer</th>
                                    <th scope="col">Échéance</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="flux in fluxes" :key="flux.id">
                                    <td>{{ flux.id }}</td>
                                    <td>{{ flux.created_at.slice(0, 10) }}</td>
                                    <td>{{ flux.collaborator.first_name }} <br> {{ flux.collaborator.last_name }}</td>
                                    <td>{{ flux.type }}</td>
                                    <td>{{ flux.title }}</td>
                                    <td>{{ cashFormat(flux.target_amount) }}</td>
                                    <td>{{ cashFormat(flux.current_amount) }}</td>
                                    <td>{{ flux.target_amount - flux.curent_amount < 0 ? cashFormat(0) :
                                        cashFormat(flux.target_amount - flux.current_amount) }}</td>
                                    <td>{{ flux.payment_date.slice(0, 10) }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <!-- End Table with stripped rows -->
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

import axios from 'axios'

export default {
    name: 'TransactionGlobalComponent',
    components: {

    },

    data() {
        return {
            resume: {
                emp: 0,
                emp_pay: 0,
                pre: 0,
                pre_pay: 0
            },
            fluxes: [],
            period: {
                date_start: null,
                date_end: null
            }
        }
    },

    mounted() {
        this.getData()
    },

    created() {

    },

    methods: {

        cashFormat(cash) {
            return (parseInt(cash)).toLocaleString('fr-FR', {
                style: 'currency',
                currency: 'CFA',
            })
        },

        resetData() {
            this.resume = {
                emp: 0,
                emp_pay: 0,
                pre: 0,
                pre_pay: 0
            }
            this.fluxes = []
        },

        getData() {
            axios.post(this.$store.state.URL_API + 'getTransactionGlobal', this.period)
                .then((res) => {
                    // console.log("GLOBAL", res)
                    if (res.data.status) {
                        this.resetData()
                        this.fluxes = res.data.fluxes
                        this.resume = res.data.resume
                    }
                })
                .catch((e) => {
                   console.error(e)
                })
        },

        alertParent() {
            this.$emit("transactionviewchange", 'main')
        }
    }
}

</script>