<template>
    <!-- Filter -->
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Choisir un jour</h5>

            <form @submit.prevent="getData()">
                <div class="row mb-3">
                    <div class="col-2">
                        <input type="date" class="form-control" v-model="this.date" required>
                    </div>
                    <button type="submit" class="btn btn-primary col-2">Procéder au filtre
                        <span class="spinner-border spinner-border-sm" v-if="load"></span>
                    </button>
                    <div class="btn btn-danger col-1 mx-1" @click="resetData()"> <i class="bi bi-arrow-repeat"></i> </div>
                </div>

                <div class="text-center">

                </div>
            </form>
        </div>
    </div>

    <!-- Liste -->
    <div class="card">
        <div class="card-body">

            <!-- Tabs -->
            <ul class="nav nav-tabs nav-tabs-bordered" id="borderedTab" role="tablist">
                <li class="nav-item" role="presentation" v-for="session in presents" :key="session[0]">
                    <button class="nav-link" :class="{ active: activePre == session[0] }" id="all-tab" data-bs-toggle="tab"
                        :data-bs-target="'#bordered-tab-p' + session[0]" type="button" role="tab" aria-controls="all"
                        aria-selected="true" @click="activatePre(session[0])">{{ session[0] }}</button>
                </li>
            </ul>

            <div class="tab-content pt-2" id="borderedTabContent">
                <div v-for="session in presents" :key="session[0]" class="tab-pane fade"
                    :class="{ 'active show': activePre == session[0] }" :id="'bordered-tab-p' + session[0]" role="tabpanel"
                    aria-labelledby="all-tab">
                    <table class="table datatable">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Nom</th>
                                <th scope="col">Prénom</th>
                                <th scope="col">Début session</th>
                                <th scope="col">Fin session</th>
                                <th scope="col">Payé</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="sess in session[1]" :key="sess.id">
                                <th scope="row"> {{ sess.number }} </th>
                                <td> {{ sess.employee.first_name }} </td>
                                <td> {{ sess.employee.last_name }} </td>
                                <td> {{ sess.created_at.slice(11, 16) }} </td>
                                <td> {{ sess.updated_at.slice(11, 16) }} </td>
                                <td><span :class="{'bg-success': sess.employee_paid_state, 'bg-danger': !sess.employee_paid_state,}" class="badge">{{sess.employee_paid_state ? 'Oui' : 'Non'}}</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

import axios from 'axios'

export default {
    name: 'RegisterPresence',

    data() {
        return {
            presents: [],
            activePre: '',
            date: null,
            load: false
        }
    },

    mounted() {
        this.getData()
    },

    methods: {
        activatePre(mess) {
            this.activePre = mess
        },

        getData() {
            this.load = true
            axios.post(this.$store.state.URL_API + 'getSessions', { date: this.date })
                .then((res) => {
                   // console.log(res)
                    if (res.data.status) {
                        this.presents = res.data.presents
                        this.activePre = this.presents[0][0]
                        this.load = false
                    }
                })
                .catch((e) => {
                   console.error(e)
                    this.load = false
                })
        },

        resetData() {
            this.date = null
            this.getData()
        }
    }
}

</script>