<template>
    <button class="btn btn-danger mb-3" @click="alertParent('main')">Annuler</button>

    <section class="section">
        <div class="row">

            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Ajouter un nouvel employé à votre équipe</h5>

                    <!-- No Labels Form -->
                    <!-- Nouveau poste -->
                    <form @submit.prevent="createEmployee()">
                        <div class="row g-3">
                            <div class="col-md-3">
                                <input v-model="employee.first_name" type="text" class="form-control" id="inputText"
                                    placeholder="Nom.." required>
                            </div>
                            <div class="col-md-3">
                                <input v-model="employee.last_name" type="text" class="form-control" id="inputText"
                                    placeholder="Prenoms.." required>
                            </div>
                            <div class="col-md-3">
                                <input v-model="employee.phone" type="text" class="form-control" id="inputText"
                                    placeholder="Contact.." required>
                            </div>
                            <div class="col-md-3">
                                <input v-model="employee.salary" type="text" class="form-control" id="inputText"
                                    placeholder="Salaire.." required>
                            </div>
                            <div class="col-md-6">
                                <select v-model="employee.post_id" class="form-select" aria-label="Default select example"
                                    required>
                                    <option value="">-- Poste --</option>
                                    <option v-for="post in posts" :value="post.id" :key="post.id">{{ post.title }}</option>
                                </select>
                            </div>
                                <button class="btn btn-primary bg-primary col-md-6">Enregister
                                    <span class="spinner-border spinner-border-sm" v-if="load"></span>
                                </button>
                        </div>

                        <div class="text-center">

                        </div>
                    </form>

                </div>
            </div>

        </div>
    </section>
</template>
  
<script>

import axios from 'axios';

export default {
    name: 'EmployeeCreateComponent',
    components: {

    },

    data() {
        return {
            posts: [],
            employee: { first_name: '', last_name: '', phone: '', post_id: '', salary: '' },
            load: false
        }
    },

    mounted() {
        this.getPosts()
    },

    created() {

    },

    methods: {
        alertParent(mess) {
            this.$emit('employeeviewchange', mess)
        },

        getPosts() {
            axios.get(this.$store.state.URL_API + 'getPosts')
                .then((res) => {
                   // console.log(res)
                    if (res.data.status) {
                        this.posts = res.data.data
                    }
                })
                .catch((e) => {
                   console.error(e)
                })
        },

        createEmployee() {
            this.load = true
            axios.post(this.$store.state.URL_API + 'createEmployee', this.employee)
                .then((res) => {
                   // console.log(res)
                    if (res.data.status) {
                        this.employee = { first_name: '', last_name: '', phone: '', post_id: '', salary: '' };
                        this.load = false
                        this.alertParent('main')
                    }
                })
                .catch((e) => {
                   console.error(e)
                    this.load = false
                })

        },
    }
};

</script>