<template>
    <button class="btn btn-danger mb-3" @click="alertParent('main')">Retour</button>
    <section class="section profile">
        <div class="row">
            <!-- Profil et Cr&ation -->
            <div class="col-xl-4">
                <!-- Profil du fournisseur -->
                <div class="card">
                    <div class="card-body profile-card pt-4 d-flex flex-column align-items-center">
                        <img src="https://cdn-icons-png.flaticon.com/512/1144/1144760.png" alt="Profile"
                            class="rounded-circle" />
                        <h2>{{ info.last_name }} {{ info.first_name }}</h2>
                        <h3 class="text-success text-bold">{{ info.type }}</h3>
                    </div>
                </div>

                <!-- Création d'un nouveau flux -->
                <div class="card">
                    <div class="card-body profile-card align-items-center">
                        <form @submit.prevent="createFlux">
                            <div class="">
                                <label for="currentPassword" class="col-form-label">Nouvelle transaction</label>
                                <div class="col-md-8 col-lg-9">
                                    <input name="emprunt" type="text" placeholder="Intitulé ici..." class="form-control"
                                        v-model="newFlux.title" required />
                                </div>
                                <br>
                                <div class="col-md-8 col-lg-9">
                                    <input name="montant" type="number" placeholder="Le montant à rembourser"
                                        class="form-control" v-model="newFlux.target_amount" required min=1000 />
                                </div>
                                <br>
                                <div class="col-md-8 col-lg-9">
                                    <select id="inputState" class="form-select" v-model="newFlux.type" required>
                                        <option value="">--Type de transaction--</option>
                                        <option value="Emprunt">Emprunt</option>
                                        <option value="Prêt">Prêt</option>
                                    </select>
                                </div>
                                <br>
                                <div class="col-md-8 col-lg-9">
                                    <input name="date" type="date" class="form-control" v-model="newFlux.payment_date"
                                        required :min="today" />
                                </div>
                                <button type="submit" class="mt-2 btn btn-primary">
                                    Ajouter +
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <!-- Détails liste et ajout -->
            <div class="col-xl-8">
                <div class="card">
                    <ul class="nav nav-tabs nav-tabs-bordered">
                        <li class="nav-item">
                            <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#emprunt"
                                @click="activeTab = 0">
                                Emprunts
                            </button>
                        </li>
                        <li class="nav-item">
                            <button class="nav-link" data-bs-toggle="tab" data-bs-target="#pret" @click="activeTab = 1">
                                Prêts
                            </button>
                        </li>
                    </ul>
                    <!-- TAB EMPRUNT -->
                    <div class="tab-pane fade profile-overview" :class="{ 'active show': activeTab == 0 }" id="emprunt"
                        v-if="activeTab == 0">
                        <div class="card-body pt-3">
                            <div v-if="emprunts.length > 0">
                                <!-- Tab des emprunts -->
                                <ul class="nav nav-tabs nav-tabs-bordered">
                                    <li class="nav-item" v-for="emprunt in emprunts" :key="emprunt.id">
                                        <button class="nav-link" :class="{ active: activeEmp == emprunt.id }"
                                            data-bs-toggle="tab" :data-bs-target="'#emprunt' + emprunt.id"
                                            v-on:click="activeFlux('emp', emprunt.id)">
                                            {{ emprunt.created_at.slice(0, 10) }}
                                        </button>
                                    </li>
                                </ul>

                                <!-- A propos d'un emprunt -->
                                <div class="tab-content pt-2">
                                    <div v-for="emprunt in emprunts" :key="emprunt.id"
                                        class="tab-pane fade profile-overview"
                                        :class="{ 'active show': activeEmp == emprunt.id }" :id="'emprunt' + emprunt.id">
                                        <!-- Détails -->
                                        <div class="card-body">
                                            <h6 class="card-title">Détails</h6>
                                            <ul class="list-group">
                                                <!-- item -->
                                                <li class="list-group-item">
                                                    <div class="d-flex justify-content-between">
                                                        <p>Intitulé</p>
                                                        <p>{{ emprunt.title }}</p>
                                                    </div>
                                                </li>
                                                <!-- item -->
                                                <li class="list-group-item">
                                                    <div class="d-flex justify-content-between">
                                                        <p>Montant à rembourser</p>
                                                        <p class="text-primary">{{ cashFormat(emprunt.target_amount) }}</p>
                                                    </div>
                                                </li>
                                                <!-- item -->
                                                <li class="list-group-item">
                                                    <div class="d-flex justify-content-between">
                                                        <p>Montant déja remboursé</p>
                                                        <p class="text-success">{{ cashFormat(emprunt.current_amount) }}
                                                        </p>
                                                    </div>
                                                </li>
                                                <!-- item -->
                                                <li class="list-group-item">
                                                    <div class="d-flex justify-content-between">
                                                        <p>Reste à payer</p>
                                                        <p class="text-danger">{{ emprunt.target_amount -
                                                            emprunt.current_amount < 0 ? cashFormat(0) :
                                                            cashFormat(emprunt.target_amount - emprunt.current_amount)
                                                        }}</p>
                                                    </div>
                                                </li>
                                                <li class="list-group-item">
                                                    <div class="d-flex justify-content-between">
                                                        <p>Echéance</p>
                                                        <p class="text-warning">{{ formatdate(emprunt.payment_date) }}</p>
                                                    </div>
                                                </li>
                                            </ul>

                                            <!-- End Default List group -->
                                            <div style="display: flex; justify-content: space-between; width: 200px;">
                                                <button type="button" class="btn btn-primary mt-4" data-bs-toggle="modal"
                                                    :data-bs-target="'#modifflux' + emprunt.id">
                                                    Modifier
                                                </button>

                                                <button type="button" class="btn btn-danger mt-4" data-bs-toggle="modal"
                                                    :data-bs-target="'#supemp' + emprunt.id">
                                                    Supprimer
                                                </button>
                                            </div>

                                            <!-- Suppression modal -->
                                            <div class="modal fade" :id="'supemp' + emprunt.id" tabindex="-1">
                                                <div class="modal-dialog modal-dialog-centered">
                                                    <div class="modal-content">
                                                        <div class="modal-header">
                                                            <h5 class="modal-title text-center text-black">
                                                                Confirmer en entrant votre code de sécurité
                                                            </h5>
                                                            <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                                aria-label="Close"></button>
                                                        </div>
                                                        <div class="modal-body">
                                                            <!-- No Labels Form -->
                                                            <form class="row g-3">
                                                                <div class="col-md-6">
                                                                    <input type="password" class="form-control"
                                                                        placeholder="******" v-model="this.inputcode" />
                                                                </div>
                                                            </form>
                                                            <!-- End No Labels Form -->
                                                        </div>
                                                        <div class="modal-footer">
                                                            <button type="button" class="btn btn-primary"
                                                                data-bs-dismiss="modal">
                                                                Annuler
                                                            </button>
                                                            <button type="button" class="btn btn-danger"
                                                                data-bs-dismiss="modal" @click="deleteFlux(emprunt.id)">
                                                                Je confirme
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Modif modal -->
                                            <div class="modal fade" :id="'modifflux' + emprunt.id" tabindex="-1">
                                                <div class="modal-dialog modal-dialog-centered">
                                                    <div class="modal-content">
                                                        <div class="modal-header">
                                                            <h5 class="modal-title text-black">Modifier l'emprunt</h5>
                                                            <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                                aria-label="Close"></button>
                                                        </div>
                                                        <form @submit.prevent="updateFlux(activeEmp)">
                                                            <div class="modal-body">
                                                                <!-- No Labels Form -->

                                                                <div class="mb-2">
                                                                    <input type="text" class="form-control"
                                                                        :placeholder="emprunt.title"
                                                                        v-model="upFlux.title" />
                                                                </div>
                                                                <div class="mb-2">
                                                                    <input type="number" class="form-control"
                                                                        :placeholder="emprunt.target_amount"
                                                                        v-model="upFlux.target_amount" min="1000" />
                                                                </div>
                                                                <div class="mb-2">
                                                                    <input type="date" class="form-control"
                                                                        :placeholder="emprunt.payment_date"
                                                                        v-model="upFlux.payment_date" :min="today" />
                                                                </div>

                                                                <!-- End No Labels Form -->
                                                            </div>
                                                            <div class="modal-footer">
                                                                <button type="button" class="btn btn-danger"
                                                                    data-bs-dismiss="modal">
                                                                    Annuler
                                                                </button>
                                                                <input type="submit" class="btn btn-primary"
                                                                    value="Enregistrer" data-bs-dismiss="modal" />

                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>



                                        <!-- Rembourssements -->
                                        <div class="card-body">
                                            <h6 class="card-title">Rembourssements effectués</h6>
                                            <!-- item de rembourssment -->
                                            <ul class="list-group" v-if="emprunt.payments.length > 0">
                                                <li class="list-group-item detail-item-color"
                                                    v-for="pay in emprunt.payments" :key="pay.id">
                                                    <div class="d-flex justify-content-between">
                                                        <p>{{ pay.created_at.slice(0, 10) }}</p>
                                                        <p>{{ cashFormat(pay.amount) }}</p>
                                                    </div>
                                                </li>
                                            </ul>
                                            <h5 v-else>Aucun rembourssement</h5>
                                            <!-- End Default List group -->
                                        </div>

                                        <!-- Nouveau rembourssment -->
                                        <div class="card-body">
                                            <h6 class="card-title">Ajouter un rembourssement</h6>
                                            <form @submit.prevent="addPayment(activeEmp)">
                                                <div class="">
                                                    <div class="col-md-8 col-lg-9">
                                                        <input v-model="newPay" name="montant" type="number"
                                                            placeholder="Montant" class="form-control" required min=1000 />
                                                    </div>
                                                    <button type="submit" class="mt-2 btn btn-primary">
                                                        Ajouter +
                                                    </button>
                                                </div>
                                            </form>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <h3 class="text-black">Aucun emprunt</h3>
                            </div>
                        </div>
                    </div>

                    <!-- TAB PRÊTS -->
                    <div clss="tab-pane fade profile-overview text-black" :class="{ 'active show': activeTab == 1 }"
                        id="pret" v-else>
                        <div class="card-body pt-3">
                            <div v-if="prets.length > 0">
                                <!-- Tab des prets -->
                                <ul class="nav nav-tabs nav-tabs-bordered">
                                    <li class="nav-item" v-for="pret in prets" :key="pret.id">
                                        <button class="nav-link" :class="{ active: activePre == pret.id }"
                                            data-bs-toggle="tab" :data-bs-target="'#pret' + pret.id"
                                            v-on:click="activeFlux('pre', pret.id)">
                                            {{ pret.created_at.slice(0, 10) }}
                                        </button>
                                    </li>
                                </ul>

                                <!-- A propos d'un pret -->
                                <div class="tab-content pt-2">
                                    <div v-for="pret in prets" :key="pret.id" class="tab-pane fade profile-overview"
                                        :class="{ 'active show': activePre == pret.id }" :id="'pret' + pret.id">
                                        <!-- Détails -->
                                        <div class="card-body">
                                            <h6 class="card-title">Détails</h6>
                                            <ul class="list-group">
                                                <!-- item -->
                                                <li class="list-group-item">
                                                    <div class="d-flex justify-content-between">
                                                        <p>Intitulé</p>
                                                        <p>{{ pret.title }}</p>
                                                    </div>
                                                </li>
                                                <!-- item -->
                                                <li class="list-group-item">
                                                    <div class="d-flex justify-content-between">
                                                        <p>Montant à rembourser</p>
                                                        <p class="text-primary">{{ cashFormat(pret.target_amount) }}</p>
                                                    </div>
                                                </li>
                                                <!-- item -->
                                                <li class="list-group-item">
                                                    <div class="d-flex justify-content-between">
                                                        <p>Montant déja remboursé</p>
                                                        <p class="text-success">{{ cashFormat(pret.current_amount) }}
                                                        </p>
                                                    </div>
                                                </li>
                                                <!-- item -->
                                                <li class="list-group-item">
                                                    <div class="d-flex justify-content-between">
                                                        <p>Reste à payer</p>
                                                        <p class="text-danger">{{ pret.target_amount -
                                                            pret.current_amount < 0 ? cashFormat(0) :
                                                            cashFormat(pret.target_amount - pret.current_amount) }}</p>
                                                    </div>
                                                </li>
                                                <li class="list-group-item">
                                                    <div class="d-flex justify-content-between">
                                                        <p>Echéance</p>
                                                        <p class="text-warning">{{ formatdate(pret.payment_date) }}</p>
                                                    </div>
                                                </li>
                                            </ul>

                                            <!-- End Default List group -->
                                            <div style="display: flex; justify-content: space-between; width: 200px;">
                                                <button type="button" class="btn btn-primary mt-4" data-bs-toggle="modal"
                                                    :data-bs-target="'#modifflux' + pret.id">
                                                    Modifier
                                                </button>

                                                <button type="button" class="btn btn-danger mt-4" data-bs-toggle="modal"
                                                    :data-bs-target="'#sup' + pret.id">
                                                    Supprimer
                                                </button>
                                            </div>

                                            <!-- Suppression modal -->
                                            <div class="modal fade" :id="'sup' + pret.id" tabindex="-1">
                                                <div class="modal-dialog modal-dialog-centered">
                                                    <div class="modal-content">
                                                        <div class="modal-header">
                                                            <h5 class="modal-title text-center text-black">
                                                                Confirmer en entrant votre code de sécurité
                                                            </h5>
                                                            <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                                aria-label="Close"></button>
                                                        </div>
                                                        <div class="modal-body">
                                                            <!-- No Labels Form -->
                                                            <form class="row g-3">
                                                                <div class="col-md-6">
                                                                    <input type="password" class="form-control"
                                                                        placeholder="******" v-model="this.inputcode" />
                                                                </div>
                                                            </form>
                                                            <!-- End No Labels Form -->
                                                        </div>
                                                        <div class="modal-footer">
                                                            <button type="button" class="btn btn-primary"
                                                                data-bs-dismiss="modal">
                                                                Annuler
                                                            </button>
                                                            <button class="btn btn-danger" data-bs-dismiss="modal"
                                                                @click="deleteFlux(pret.id)">
                                                                Je confirme
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div class="modal fade" :id="'modifflux' + pret.id" tabindex="-1">
                                            <div class="modal-dialog modal-dialog-centered">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title">Modifier le prêt</h5>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                            aria-label="Close"></button>
                                                    </div>
                                                    <form @submit.prevent="updateFlux(activePre)">
                                                        <div class="modal-body">
                                                            <!-- No Labels Form -->

                                                            <div class="col-md-6 mb-2">
                                                                <input type="text" class="form-control"
                                                                    :placeholder="pret.title" v-model="upFlux.title" />
                                                            </div>
                                                            <div class="col-md-6 mb-2">
                                                                <input type="number" class="form-control"
                                                                    :placeholder="pret.target_amount"
                                                                    v-model="upFlux.target_amount" min="1000" />
                                                            </div>
                                                            <div class="col-md-6 mb-2">
                                                                <input type="date" class="form-control"
                                                                    :placeholder="pret.payment_date"
                                                                    v-model="upFlux.payment_date" :min="today" />
                                                            </div>

                                                            <!-- End No Labels Form -->
                                                        </div>
                                                        <div class="modal-footer">
                                                            <button type="button" class="btn btn-danger"
                                                                data-bs-dismiss="modal">
                                                                Annuler
                                                            </button>
                                                            <input type="submit" class="btn btn-success" value="Enregistrer"
                                                                data-bs-dismiss="modal" />

                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Rembourssements -->
                                        <div class="card-body">
                                            <h6 class="card-title">Rembourssements effectués</h6>
                                            <!-- item de rembourssment -->
                                            <ul class="list-group" v-if="pret.payments.length > 0">
                                                <li class="list-group-item detail-item-color" v-for="pay in pret.payments"
                                                    :key="pay.id">
                                                    <div class="d-flex justify-content-between">
                                                        <p>{{ formatdate(pay.created_at.slice(0, 10)) }}</p>
                                                        <p>{{ cashFormat(pay.amount) }}</p>
                                                    </div>
                                                </li>
                                            </ul>
                                            <h5 v-else>Aucun rembourssement</h5>
                                            <!-- End Default List group -->
                                        </div>

                                        <!-- Nouveau rembourssment -->
                                        <div class="card-body">
                                            <h6 class="card-title">Ajouter un rembourssement</h6>
                                            <form @submit.prevent="addPayment(activePre)">
                                                <div class="">
                                                    <div class="col-md-8 col-lg-9">
                                                        <input v-model="newPay" name="montant" type="number"
                                                            placeholder="Montant" class="form-control" required min=1000 />
                                                    </div>
                                                    <button type="submit" class="mt-2 btn btn-primary">
                                                        Ajouter +
                                                    </button>
                                                </div>
                                            </form>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <h3 class="text-black">Aucun prêt</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <vue-basic-alert :duration="300" :closeIn="1500" ref="alert" />
    </section>
</template>

<script>

import axios from 'axios';

export default {
    name: 'TransactionDetailComponent',
    components: {

    },

    computed: {
        today() {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();

            today = yyyy + '-' + mm + '-' + dd;
            return today
        }
    },

    props: {
        id: {
            required: true
        }
    },

    data() {
        return {
            info: {
                last_name: null,
                first_name: null,
                type: null
            },
            emprunts: [],
            prets: [],
            newFlux: {
                title: null,
                target_amount: null,
                payment_date: null,
                type: '',
                collaborator_id: this.id
            },
            upFlux: {
                title: null,
                target_amount: null,
                payment_date: null,
            },
            newPay: null,
            activeEmp: 0,
            activePre: 0,
            activeTab: 0,
            inputcode: ''
        }
    },

    mounted() {
        this.getData()
    },

    created() {

    },

    methods: {

        alertParent() {
            this.$emit("transactionviewchange", 'main')
        },

        cashFormat(cash) {
            return (parseInt(cash)).toLocaleString('fr-FR', {
                style: 'currency',
                currency: 'CFA',
            })
        },

        formatdate(date) {
            var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
            var thdeate = new Date(date)
            return thdeate.toLocaleDateString("fr-FR", options)
        },

        activeFlux(type, id) {
            if (type == 'emp')
                this.activeEmp = id
            else
                this.activePre = id
        },

        async getData() {
            axios.get(this.$store.state.URL_API + "getTransactionDetails/" + this.id)
                .then((res) => {
                    // console.log("FOURNISSEUR : ", res.data.data)
                    if (res.data.status) {
                        this.info = { last_name: res.data.data.last_name, first_name: res.data.data.first_name, type: res.data.data.type }
                        this.newPay = null
                        this.emprunts = res.data.data.emprunts
                        this.prets = res.data.data.prets
                        if (this.emprunts.length > 0 && this.activeEmp == 0) {
                            this.activeEmp = this.emprunts[0].id
                        }
                        if (this.prets.length > 0 && this.activePre == 0) {
                            this.activePre = this.prets[0].id
                        }
                    } else {
                        // console.log("SORRY : ", res.data.message)
                    }
                })
                .catch((e) => {
                    console.error(e)
                })
        },

        createFlux() {
            axios.post(this.$store.state.URL_API + 'createFlux', this.newFlux)
                .then((res) => {
                    if (res.data.status) {
                        this.newFlux = {
                            title: null,
                            target_amount: null,
                            payment_date: null,
                            type: '',
                            collaborator_id: this.id
                        }
                        this.getData()
                    } else {
                        this.$refs.alert
                            .showAlert(
                                'error',
                                res.data.message,
                                "Désolé",
                            )
                    }
                })
                .catch((e) => {
                    console.error(e)
                })
        },

        updateFlux(id) {
            axios.post(this.$store.state.URL_API + 'updateFlux/' + id, this.upFlux)
                .then((res) => {
                    if (res.data.status) {
                        this.upFlux = {
                            title: null,
                            target_amount: null,
                            payment_date: null,
                        }
                        this.getData()
                    } else {
                        this.$refs.alert
                            .showAlert(
                                'error',
                                res.data.message,
                                "Désolé",
                            )
                    }
                })
                .catch((e) => {
                    console.error(e)
                })
        },

        deleteFlux(id) {
            axios.post(this.$store.state.URL_API + 'deleteFlux/' + id, { code: this.inputcode })
                .then((res) => {
                    // console.log(res)
                    if (res.data.status) {
                        this.inputcode = ""
                        this.getData()
                        this.$refs.alert
                            .showAlert(
                                'success',
                                '',
                                "Effectué avec succès",
                            )
                    } else {
                        this.$refs.alert
                            .showAlert(
                                'error',
                                res.data.message,
                                "Désolé",
                            )
                    }
                })
                .catch((e) => {
                    console.error(e)
                })
        },

        addPayment(id) {
            axios.post(this.$store.state.URL_API + 'addPayment', { flux_id: id, amount: this.newPay })
                .then((res) => {
                    if (res.data.status) {
                        this.$refs.alert
                            .showAlert(
                                'success',
                                '',
                                "Effectué avec succès",
                            )
                        this.getData()
                    } else {
                        this.$refs.alert
                            .showAlert(
                                'error',
                                res.data.message,
                                "Désolé",
                            )
                    }
                })
                .catch((e) => {
                    console.error(e)
                    this.$refs.alert
                            .showAlert(
                                'error',
                                e.response.data.message,
                                "Désolé",
                            )
                })

        }
    }
};

</script>